import styled from "styled-components";

export const Container = styled.header`
  overflow: hidden;
  position: fixed; 
  top: 0; 
  width: 100%;
  background: var(--heading-color);
  z-index: 2;
  margin-bottom: 2rem; 
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;  
`;

export const ContentSub = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
`;

// export const ButtonMoreInfo = styled.a`
//   text-decoration: none;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   width: 200px;
//   height: 50px;
//   background-color: var(--heading-color2);

//   border-radius: 1rem;
//   font-size: 17px;
//   font-weight: 500;
//   color: var(--white);
//   transition: 1s;

//   &:hover {
//     background-color: var(--white);
//     color: #222;
//   }
//   div {
//     margin-top: 5px;
//     margin-right: 8px;
//   }
// `;


