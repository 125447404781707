import styled from 'styled-components';


export const Container = styled.div`
  /* max-width: 1700px; */
  margin: 4.5rem 2.5rem auto;
  padding: 2.5rem 1rem;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 1rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 2.5rem 0;
  }
`;


export const ContainerMain = styled.div`
/* max-width: 1700px; */
  margin: 0 0 40px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
    gap: 0rem;
  }
`;


export const ContainerMainMinus = styled.div`
/* max-width: 1700px; */
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
    gap: 0rem;
  }
`;


export const DivWhiteList = styled.div`
  @media (max-width: 900px) {
    margin-top: 10px;
  }
`;

export const ContainerMainSelect = styled.div`
/* max-width: 1700px; */
  margin: 0 0 40px 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  

  justify-content: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 20px;
    gap: 0rem;
  }
`;


export const ContainerPrinc = styled.div`
/* max-width: 1700px; */
  margin: 0 0 40px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
  }
`;


export const ContainerMainMin = styled.div`
/* max-width: 1700px; */
  margin: 0 0 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
  }
`;



export const ContainerTop = styled.div`
/* max-width: 1700px; */
  margin: 1.5rem 2.5rem auto;
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    margin: 2.5rem 1rem 1rem 1rem;
    padding: 0;
    flex-wrap: wrap;
  }

`;


export const ContainerTopMin = styled.div`
/* max-width: 1700px; */
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    /* margin: 10px 0 10px 0; */
    padding: 0;    
  }
`;


export const ColLat = styled.div`
  margin-top: 0px;
  @media (max-width: 900px) {
    margin-top: 20px;
  }
`;


export const ColLatMax = styled.div`
 width: 1700px;
  margin-top: 0px;


  justify-content: center;
  align-items: center;
  text-align: center;

    @media (max-width: 900px) {
      margin-top: 20px;
    }
`;

export const ColButton = styled.div`
margin: 20px 0 20px 0 ;

@media (max-width: 900px) {
  display: flex;
  flex-direction: row;
}
`;


export const ButtonNada = styled.button`
   width: 100%;
   padding: 12px;
   margin-bottom: 25px;
   /* margin-left: 4px; */
   color: #000000;

   background-color: #d3d3d3;
   border:  0px solid;
   border-radius: 20px;
   font-size: 18px;

   /* cursor: pointer; */

   @media (max-width: 900px) {
      margin-bottom: 5px;
    }

   /* @media (max-width: 375px) {
      
   } */
`

export const Button = styled.button`
   width: 100%;
   padding: 10px;
   margin-bottom: 25px;
   /* margin-left: 4px; */
   color: #FFFFFF;

   background-color: #00414a;
   border:  0px solid;
   border-radius: 20px;
   font-size: 20px;

   cursor: pointer;

   @media (max-width: 900px) {
      margin-bottom: 5px;
    }

   /* @media (max-width: 375px) {
      
   } */
`

export const Button3 = styled.button`
   width: 100%;
   padding: 8px;
   margin-top: 25px;   

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 20px;

   /* cursor: pointer; */
`;

export const Button6 = styled.button`
  width: 100%;
  padding: 8px;
  margin: 0px 0px 10px 0px;

  background-color: #f3fbf0;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  color: #000000;
  border:  0px solid;
  border-radius: 20px;

  /* cursor: pointer; */


  @media (max-width: 900px) {
    width: 100%;
    padding: 4px;
    margin: 20px 0px 20px 0px;
  }
`;

export const Button4 = styled.button`
   width: 100%;
   padding: 8px;
   margin-top: 5px;   

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 20px;

   /* cursor: pointer; */
`;

export const Button5 = styled.button`
   width: 100%;
   max-width: 640px;
   padding: 8px;
   margin-top: 5px;   

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 20px;

   /* cursor: pointer; */
`;

export const Button2 = styled.button`
   width: 150px;
   padding: 8px;
   margin: 15px 0px 10px 0px;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 20px;

   cursor: pointer;


   @media (max-width: 900px) {
    width: 50%;
    padding: 4px;
    margin: 10px;
   }

`;


export const DivCard = styled.div`
   max-width: 480px;
   padding: 8px;
   margin: 15px 0px 10px 0px;

   justify-content: center;
   align-items: center;
   text-align: center;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 10px;

   @media (max-width: 900px) {
    /*
    width: 50%;
    */
    padding: 4px;
    margin: 10px;
   }

`;


export const DivCardMin = styled.div`
   width: 250px;
   padding: 8px;
   margin: 15px 0px 10px 0px;

   justify-content: center;
   align-items: center;
   text-align: center;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 10px;

   @media (max-width: 900px) {
    width: 50%;
    padding: 4px;
    margin: 10px;
   }

`;


export const CardMain = styled.div`
  max-width: 480px;
  margin: 4.5rem 2.5rem auto;
  padding: 20px;
  background-color: #f3fbf0;


  

  border-radius: 10px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  justify-content: center;

`


export const Card = styled.div`
  max-width: 480px;
  /* margin: 4px 4px auto; */
  padding: 20px;
  background-color: #f3fbf0;

  border-radius: 10px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  justify-content: center;

`


export const CardSelect = styled.div`
  max-width: 480px;
  margin: 0px 0px 0px 0px ;
  padding: 10px;
  background-color: #f3fbf0;

  border-radius: 10px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  justify-content: center;

`

export const BoxTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 250px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ImageBox = styled.img`
  width: 40px;
  height: 40px;
  margin: 5px 0px 0px 0px;
  /* transition: opacity 1s ease-in-out; */
`

export const ImageBoxMax = styled.img`
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 25px;
  /* transition: opacity 1s ease-in-out; */
`

export const Image = styled.img`
  justify-content: center;
  max-width: 300px;
  /* transition: opacity 1s ease-in-out; */
`

export const ImageLarge = styled.img`
  justify-content: center;
  max-width: 640px;
  /* transition: opacity 1s ease-in-out; */
`


export const ImageBlank = styled.img`
  justify-content: center;
  max-width: 300px;
  /* transition: opacity 1s ease-in-out; */
`

export const BoxTitle = styled.div`
  margin: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: var(--light-gray);
    }
    strong {
      font-size: 25px;
    }
`

export const TextGray = styled.div`
   color: #adadad;
   font-size: 16px;
`;

export const Text = styled.div`
   
`;


export const TextBalance = styled.div`
  text-align: center;
  font-size: 18px;
`;


export const TextPrice = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 22px;
`;

export const ContainerButton = styled.div`
   
    display: flex;

   @media (max-width: 900px) {
    width: 100%;    
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

`;

export const ContainerButton2 = styled.div`
   
`;

export const ContainerButton3 = styled.div`

  margin: 20px 0 20px 0 ;

  

  @media (max-width: 900px) {
    /*
      display: flex;
      flex-direction: row;
    */
  }
`;

export const imgright = styled.img`
    order: 1;
`;

export const BalanceConteiner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 45px 0 45px;
  text-align: center;
  font-size: 22px;

  p {
    font-size: 18px;
    font-weight: 400;
    color: var(--light-gray);
  }
`
export const BalanceContent = styled.div`

`
export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  margin-top: 20px;

  strong {
    font-size: 18px;
  }
  p {
    font-size: 15px;
    color: var(--light-gray);
  }
`

export const StatisticsContent = styled.div`

`