import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { ThemeProvider } from 'styled-components'
import { light } from '@pancakeswap-libs/uikit'

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
      <ThemeProvider theme={light}>...
        <App />
      </ThemeProvider>
  </React.StrictMode>
);
