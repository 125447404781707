import React, { useState, useEffect, useMemo } from 'react';

import { Main } from "./components/Main";
import { MainEth } from "./components/MainEth";
import { GlobalStyle } from "./styles/global";
import { Container, Content, ContentSub } from "./components/Header/style";
import logoImg from './assets/logo-zefi.png';
import logoEth from './assets/eth.svg';
import logoBsc from './assets/bsc.svg';
import nada from './assets/nada.png';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { convertUtf8ToHex } from "@walletconnect/utils";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Torus from "@toruslabs/torus-embed";
import ContentLoader from "react-content-loader"
import unknowntoken from './assets/unknown-token.png';
import { tokens , tokensCake , myTokens} from "./helpers/tokens";
import AsyncStorage from '@react-native-async-storage/async-storage';

// import { Button } from "@pancakeswap-libs/uikit";
import styled from "styled-components";
import { ellipseAddress, getChainData } from "./helpers/utilities";
import { buyBscABI , buyBscABIold } from "./contract/abi/buyBscABI";
import buyEthABI from "./contract/abi/buyEthABI";
import { zefiAddress , zboAddress, buyBscAddress, buyEthAddress } from "./contract/contracts";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Select, { components } from "react-select";

import creditcard from './assets/creditcard.png';
import banner from './assets/banner.jpg';
import logoZefi from './assets/zefi.png';
import logoZbo from './assets/zbo.svg';
import cards from './assets/cards.png';
import { FaExternalLinkAlt } from "react-icons/fa";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";





//1. Import coingecko-api
const CoinGecko = require('coingecko-api'); 


const Web3EthAccounts = require('web3-eth-accounts');
  
const privateKey = '';
let isConnect = false;

// const tokenAddress = '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07';  // ZEFI
// const zboAddress = '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8';  // ZBO
// const presaleAddress = '0x97d0b13678bc7c9fa883a2cf57b10f12e2e9f802';
// const buyBscContract = '0x2CA5fDc1Cd421beAf1d83f199f3F5d407CF82F9e';

let maxSupply = 50000000, eventFlag = false, checkFlag = false;    
const tokenRate = 0.0001176;
const allMyTokens = [];

// const chainETH  = 4;
const chainETH  = 1;

const chainBSC  = 56;

// const chainNft = 56; // bsc
const chainNft = chainETH; // rinkeby

let enterChainId =  chainETH;
let isZefi = true;
let newToken = '0x';
let balanceEth = 0;
let saldoDesc;
let tokenTicker = 'ZEFI';
let referral = '0x0000000000000000000000000000000000000000';


const storeData = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value)
  } catch (e) {
    // saving error
  }
}


const getData = async (key) => {
  let thisData;
  try {
    thisData = await AsyncStorage.getItem(key)
  } catch (e) {
    // saving error
  }

  return thisData;
}

const testData = async () => {
  
  try {

    if(currentAccount.charAt(0) == 0 && currentAccount.charAt(1) == 'x' && currentAccount.length ==  42){

       if((currentAccount.toLowerCase() != referral.toLowerCase()) && referral != '0x0000000000000000000000000000000000000000'){
        storeData('@referral', referral);  
       }

      const nreferral = await getData('@referral')
      console.log('##########################################')
      console.log('currentAccount: '+ currentAccount)
      console.log('referral this: '+ referral)
      console.log('referral db: '+ nreferral)
      console.log('isZefi: '+ isZefi)
      console.log('newToken: '+ newToken)
      console.log('##########################################')
    }
  
    } catch (e) {
      console.log('e: '+ e)
    }
}

try {
  let checkContract;
  let pathname;

  if(window.location.pathname.includes('/')){
    pathname = window.location.pathname.substring(1);
    pathname = pathname.split( '/' );
    checkContract = pathname[0];
    tokenTicker = pathname[1];
    try{
      referral = pathname[2];
      if(referral.charAt(0) != 0 || referral.charAt(1) != 'x' || referral.length !=  42){
        referral = '0x0000000000000000000000000000000000000000';
      }      
    } catch (e) {
      referral = '0x0000000000000000000000000000000000000000';
    }

  }else{
    checkContract = window.location.pathname;
    checkContract = checkContract.substring(1);
    referral = '0x0000000000000000000000000000000000000000';
  }

  if(referral != '0x0000000000000000000000000000000000000000'){
    // storeData('@referral', referral);  
  }
  
  // console.log('checkContract: ' + checkContract);
  // console.log('tokenTicker: ' + tokenTicker);
  // // console.log('checkContract: ' + window.location.pathname);
  // // console.log(checkContract.charAt(0) + checkContract.charAt(1));
  // // console.log(checkContract.length);

  if(checkContract.charAt(0) == 0 && checkContract.charAt(1) == 'x' && checkContract.length ==  42){
    isZefi = false;
    newToken = checkContract;
  }

} catch (e) {
  console.log('isZefi error: '+ e.message)
  isZefi = true;
  // Unexpected token n in JSON at position 2
}

// testData();

// const accounts = new Web3EthAccounts('https://bsc-dataseed.binance.org/');  
// const accounts = new Web3EthAccounts('https://rinkeby.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d');  

  const infuraId = '';

  const newproviderOptions = {
    walletconnect: {
      package: WalletConnect,
      options: {
        infuraId
      }
    },
    torus: {
      package: Torus
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: "ZCoreClub NFT",
        infuraId
      }
    }
  };


let web3Mod;
let connected;
let currentAccount = 'Connect Wallet';
let currentWeb3, currentProvider, currentChainId, currentBalance;
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "",
      bridge: "https://bridge.walletconnect.org",
      qrcodeModalOptions: {        
        mobileLinks: [
          "metamask",
          "imtoken",
        ]
      }
    }
  }
};

export const ImageLarge = styled.img`
  width: 100%;
  justify-content: center;
  max-width: 640px;
  /* transition: opacity 1s ease-in-out; */
`

export const transitions = {
  short: "all 0.1s ease-in-out",
  base: "all 0.2s ease-in-out",
  long: "all 0.3s ease-in-out",
  button: "all 0.15s ease-in-out",
};

export const Text = styled.div`
  color: #FFFFFF;  
`;

export const ImageBox = styled.img`
  width: 30px;
  height: 30px;
  margin: 5px;
`

export const ImageBoxMin = styled.img`
  width: 35px;
  height: 35px;
  margin: 5px 5px 5px 15px ;
  cursor: pointer;
`

export const Logo = styled.img`

`

const FooterCard = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #00414a;
  position: relative;
  padding: 20px;

  justify-content: center;
  text-align: center;
  color: #FFFFFF;  
`;

const Footer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 10px;
  background-color: #00414a;
  position: relative;
  padding: 20px;

  justify-content: center;
  text-align: center;
  color: #FFFFFF;  
`;

const SAddress = styled.p`
  transition: ${transitions.base};
  font-weight: bold;
  margin: -2px auto 0.7em";
`;

const Button = styled.button`
/* Adapt the colors based on primary prop */
background: ${props => props.primary};
color: #00414a;

font-size: 1em;
margin: 1em;
padding: 0.25em 1em;
border: 2px solid #00414a;
border-radius: 20px;
cursor: pointer;
`;

export const Button2 = styled.button`
  width: 100%;
   padding: 8px;
   margin: 15px 10px 10px 10px;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 20px;

   cursor: pointer;
`;

export const ContainerButton = styled.div`
   display: flex;
`;

export const ContainerButton2 = styled.div`
   
`;


export const Card = styled.div`
  max-width: 700px;
  margin: 0 0 20px 0;
  /* margin: 4px 4px auto; */
  padding: 5px;
  background-color: #f3fbf0;

  border-radius: 10px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  justify-content: center;

  @media (max-width: 900px) {
    margin: 20px 20px 20px 20px;
  }  

`

export const BoxTitle = styled.div`
  margin: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: var(--light-gray);
    }
    strong {
      font-size: 25px;
    }
`


export const BoxTitleCard = styled.div`
  margin: 10px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: var(--light-gray);
    }
    strong {
      font-size: 25px;
    }
`

export const ContainerButton3 = styled.div`

  margin: 20px 0 20px 0 ;

  @media (max-width: 900px) {
    /*
      display: flex;
      flex-direction: row;
    */
  }
`;


export const DivCard = styled.div`
   max-width: 480px;
   padding: 8px;
   margin: 5px 0px 10px 0px;

   justify-content: center;
   align-items: center;
   text-align: center;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 10px;

   @media (max-width: 900px) {
    /*
      width: 50%;
      padding: 4px;
      margin: 10px;
    */
   }

`;


const ColoredLine = ({ color }) => (
  <hr
      style={{
         marginTop: '10px',
         marginBottom: '10px',
          color: color,
          backgroundColor: color,
          height: 3
      }}
  />
);


const init = () => {
  
  if (window.ethereum) {
    window.ethereum.autoRefreshOnNetworkChange = false;
    //if(window.ethereum.isMetaMask){
      //// console.log("MetaMask is aleady install.");
      web3Mod = new Web3Modal({cacheProvider: false, newproviderOptions}); 
      // web3Mod.clearCachedProvider();
      // disconnect();
    //}else {    
      // alert("Installed extenssion is not a MetaMask.");
    //}
  }else {    
      // alert("Please install MetaMask. DApp can't work rightly.");
  }
};
init();






const Web3ModalComponent = (bntid) => {
  
  const [tokenUSD, setTokenUSD] = useState(0)
  const [dbMy, setDbMy] = useState([])
  const [ethPrice, setEthPrice] = useState(0)

  const getEthPrice = async () => {
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.coins.fetch('ethereum');
    if(data){
      // console.log(data.data.market_data.current_price.usd);
      setEthPrice(data.data.market_data.current_price.usd);
    }  
  }  
  

  useEffect(() => {
    getEthPrice();
    testData();
   }, [currentWeb3]);

  const createListTokens = async () => {
    const dataTokens = '[            "tokenName": "Wrapped ZCore (WZCR)",             "tokenSymbol": "WZCR",             "contractAddress": "0x1f01dc57c66c2f87d8eab9c625d335e9defe6912"             "tokenName": "ZBoost (ZBO)",             "tokenSymbol": "ZBO",             "contractAddress": "0x7d3550d0b0ac3590834ca6103907cd6dd41318f8"             "tokenName": "ZCore Finance (ZEFI)",             "tokenSymbol": "ZEFI",             "contractAddress": "0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07"]';
    
    const jsonValue = await AsyncStorage.getItem('@mytokens')
    if(jsonValue === null){
      storeData('@mytokens' , dataTokens);
    }
}

  const setmytoken = (event) => {
    const value = event.contractAddress;
    // console.log(event);
    if (typeof window !== 'undefined') {
       let newUrl;
       if(window.location.hostname == 'localhost'){
          newUrl = 'http://localhost:3000/' + value;
       }else{
          newUrl = 'https://' + window.location.hostname + '/' + value;
       }
       // console.log(newUrl);
       window.location.href = newUrl;
  }
  }
  
  const dbMyTokens = async () => {
    const jsonValue = await AsyncStorage.getItem('@mytokens')
    const dataTokens = JSON.parse(jsonValue);   
    setDbMy(dataTokens)
    // const arr = []
    // Object.keys(MyObject).forEach(key => arr.push({name: key, value: MyObject[key]}))
  }

  useEffect(() => {
    // dbMyTokens();
   }, []);

   
  // createListTokens();

  const storeData = async (key, value) => {
    try {
      await AsyncStorage.setItem(key, value)
    } catch (e) {
      // saving error
    }
  }

  const getData = async (key) => {
    let thisData;
    try {
      thisData = await AsyncStorage.getItem(key)
    } catch (e) {
      // saving error
    }

    return thisData;
  }

  function multiDimensionalUnique(arr) {
    var uniques = [];
    var itemsFound = {};
    for(var i = 0, l = arr.length; i < l; i++) {
        var stringified = JSON.stringify(arr[i]);
        if(itemsFound[stringified]) { continue; }
        uniques.push(arr[i]);
        itemsFound[stringified] = true;
    }
    return uniques;
}

function sortFunction(a, b) {
  if (a[0] === b[0]) {
      return 0;
  }
  else {
      return (a[0] < b[0]) ? -1 : 1;
  }
}

const funcGetPriceZefi = async () => {
  if(currentWeb3){
    if(currentChainId == chainETH){
      setTokenUSD(1000);
    }else if(currentChainId == chainBSC){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      await buyBscContract.methods.getPriceUsd(zefiAddress, '1000000000000000000').call().then(function (response) {
         let tempUsd = (response/1e18);
         setTokenUSD(tempUsd);
         // console.log('tokenPriceUSD: ' + tempUsd);
         // console.log('tokenPriceUSD: ' + tokenUSD);
      });
    }else{
      // setTokenUSD(0);
    }
  }     
}

useEffect(() => {   
  funcGetPriceZefi();
}, [currentWeb3]);  

  const listMyTokens = async (currentAccount) => {

    myTokens
    .get('?module=account&action=tokentx&page=1&offset=300&startblock=0&endblock=999999999&sort=desc&apikey=XPN8PKZMHGXX29CDM6TUJNM5HY4S4H7PVM&address=' + currentAccount)
    .then(function (response) {
        // // console.log('listMyTokens: '+ JSON.stringify(response.data.result));
        // // console.log('listMyTokens: ' + response.data.result.length);
        const dataTokens = response.data.result;


       // storeData('@tokens_zefi', JSON.stringify(dataTokens));

       try {
          // if(!isZefi){
                for (let i = 0; i < dataTokens.length; i++) {      
                   // // console.log('pancake: ' + newToken);
                   // // console.log('pancake: ' + dataTokens[i].address);                  
                      if(dataTokens[i].tokenSymbol !== 'Cake-LP'){

                        let tempName = dataTokens[i].tokenName + ' ('+ dataTokens[i].tokenSymbol.toUpperCase() +')';

                        var valueToPush = { 
                          tokenName : tempName,
                          tokenSymbol : dataTokens[i].tokenSymbol,
                          contractAddress : dataTokens[i].contractAddress
                        }; // or "var valueToPush = new Object();" which is the same

                        // // console.log(valueToPush)

                        if(dataTokens[i].from.toLowerCase() == currentAccount.toLowerCase()){
                          allMyTokens.push(valueToPush);
                        }

                        //  // console.log('listMyTokens #################################################');
                        // // console.log('listMyTokens: ' + dataTokens[i].tokenName);
                         // // console.log('listMyTokens: ' + dataTokens[i].tokenName);
                         // // console.log('listMyTokens: ' + dataTokens[i].tokenName);
                         // // console.log('listMyTokens: ' + dataTokens[i].tokenSymbol);
                        // // console.log('listMyTokens: ' + dataTokens[i].contractAddress);
                         // // console.log('listMyTokens #################################################');   
                      }
                }
          // }
       } catch (e){            
         console.error('listMyTokens: '+ e.msg)
       }

       
       
       const temp_mytokens = JSON.stringify(multiDimensionalUnique(allMyTokens).sort(sortFunction));
       // storeData('@mytokens', temp_mytokens);
       // // console.log(allMyTokens);

       const resultsByObjectId = sortByProperty(JSON.parse(temp_mytokens), 'tokenName');
       // // console.log(JSON.stringify(resultsByObjectId));

       storeData('@mytokens', JSON.stringify(resultsByObjectId));

       // const resultsByObjectId = sortByProperty(results, 'attributes.OBJECTID');
       // const resultsByObjectIdDescending = sortByProperty(results, 'attributes.OBJECTID', -1);

       // const temData = multiDimensionalUnique(allMyTokens).sort(sortFunction);
       // let sortedData = temData.slice().sort((a, b) => b.id - a.id);
       // // console.log(JSON.stringify(sortedData))


       // console.table(multiDimensionalUnique(allMyTokens).sort(sortFunction));
       // console.table(JSON.stringify(multiDimensionalUnique(allMyTokens).sort(sortFunction)));
     
     })
    .catch((err) => {
    }); 
  }


  function sortByProperty(objArray, prop, direction){
    if (arguments.length<2) throw new Error("ARRAY, AND OBJECT PROPERTY MINIMUM ARGUMENTS, OPTIONAL DIRECTION");
    if (!Array.isArray(objArray)) throw new Error("FIRST ARGUMENT NOT AN ARRAY");
    const clone = objArray.slice(0);
    const direct = arguments.length>2 ? arguments[2] : 1; //Default to ascending
    const propPath = (prop.constructor===Array) ? prop : prop.split(".");
    clone.sort(function(a,b){
        for (let p in propPath){
                if (a[propPath[p]] && b[propPath[p]]){
                    a = a[propPath[p]];
                    b = b[propPath[p]];
                }
        }
        // convert numeric strings to integers
        a = a.match(/^\d+$/) ? +a : a;
        b = b.match(/^\d+$/) ? +b : b;
        return ( (a < b) ? -1*direct : ((a > b) ? 1*direct : 0) );
    });
    return clone;
}



  useMemo(() => {
    if(currentAccount !== undefined){
      if(currentAccount.charAt(0) == '0' && currentAccount.charAt(1) == 'x' && currentAccount.length ==  42){
        listMyTokens(currentAccount);
      }
    }
  }, [currentAccount]); // Apenas re-execute o efeito quando o count mudar
  

  const saveLogo = async () => {

  tokens
     .get("/src/constants/token/pancakeswap.json?version=2")
     .then(function (response) {
        // // console.log(response);
        // // console.log('logoToken: ' + response.data.tokens.length);
        const dataTokens = response.data.tokens;


        storeData('@tokens_zefi', JSON.stringify(dataTokens));
/*
        try {
           if(!isZefi){
                 for (let i = 0; i < dataTokens.length; i++) {      
                    // // console.log('pancake: ' + newToken);
                    // // console.log('pancake: ' + dataTokens[i].address);
                    if((newToken.toLowerCase() === dataTokens[i].address.toLowerCase()) && dataTokens[i].logoURI !== undefined){
                       // setLogoToken(dataTokens[i].logoURI);
                       // console.log('logoToken #################################################');
                       // console.log('logoToken: ' + dataTokens[i].name);
                       // console.log('logoToken: ' + dataTokens[i].logoURI);
                       // console.log('logoToken #################################################');   
                    }         
                 }
           }
        } catch (e){            
        }
*/          
      })
     .catch((err) => {
     });



     // // console.log('logoToken: start!')
      tokensCake
         .get("/pancakeswap-extended.json")
         .then(function (response) {
           // // console.log('logoToken: ' + response.data.tokens.length);
           const listTokesCake = response.data.tokens;

           storeData('@tokens_cake', JSON.stringify(listTokesCake));
/*
           try {
              if(!isZefi){
                    for (let i = 0; i < listTokesCake.length; i++) {      
                       if((newToken.toLowerCase() === listTokesCake[i].address.toLowerCase()) && listTokesCake[i].logoURI !== undefined){
                          // setLogoToken(listTokesCake[i].logoURI);
                          // console.log('logoToken #################################################');
                          // console.log('logoToken: ' + listTokesCake[i].name);
                          // console.log('logoToken: ' + listTokesCake[i].logoURI);
                          // console.log('logoToken #################################################');   
                       }         
                    }
              }
           } catch (e){
           }  
*/           
         })
         .catch((err) => {            
         });      
}
// saveLogo();


// const [logoToken, setLogoToken] = useState(unknowntoken)
// // console.log('logoToken: isZefi ' + isZefi);
/*
 useEffect(() => {
  if(isZefi){
     setLogoToken('https://zcore.network/coins/ZEFI.png');
  }else{
     getLogo();
  }
 }, [isZefi]); // Apenas re-execute o efeito quando o count mudar
 */


  // // console.log('logoToken: '+ logoToken);

  // const [allowanceZefi, setallowanceZefi] = useState(0);

  // let logoNetwork = logoEth;
  const [descBalance, setdescBalance] = useState('');
  const [logoNetwork, setlogoNetwork] = useState(nada);
  const [provider, setProvider] = useState(null);
  const [myadd, setMyAdd] = useState('Connect Wallet');
  // const [myWalletBalance, setmyWalletBalance] = useState(0);
  // const [myEthBalance, setmyEthBalance] = useState(0);
  // const [checkBalance, setCheckBalance] = useState(false);

  const [crossmintQtd, setCrossmintQtd] = useState(0);

  /*
  try {
    // console.log('coingecko: ' + JSON.parse(datacoingecko));    
  } catch (e) {
    // console.log("coingecko: Error: ", e);
    return;
  }
  */
  


  const connect = async () => {
    let _provider;
    try {
      _provider = await web3Mod.connect();
      // await web3Mod.toggleModal();
    } catch (e) {
      // console.log("Could not get a wallet connection", e);
      return;
    }
    if (_provider) {
      // RENDER 
      setProvider(_provider);
      _provider.on("accountsChanged", (accounts) => {      
        fetchAccountData(_provider);
        // console.log("accountsChanged");
      });
      _provider.on("chainChanged", (chainId) => {
        // window.location.reload();
        fetchAccountData(_provider);
        // console.log("chainChanged");
      });
      _provider.on("networkChanged", (networkId) => {
        fetchAccountData(_provider);
        // console.log("networkChanged");
      });
      _provider.on("disconnect", (code, reason) => {
        // console.log("disconnect", code, reason); 
      });
      await fetchAccountData(_provider);
      connected = true;
    }
  };

  try {
    // connect();
  } catch (e) {
    // console.log("Error init connect(): ", e);
    return;
  }
  

  const disconnect = async () => {
    // console.log("Killing the wallet connection", currentProvider);
    await web3Mod.clearCachedProvider();
    isConnect = false;
    // RENDER 
    setProvider(null);
    currentProvider = null;
    currentAccount = "";
    // RENDER 
    setMyAdd('Connect Wallet')
    // setmyWalletBalance(0)
    // RENDER 
    setdescBalance('')
    // RENDER 
    setlogoNetwork(nada);
    connected = false;
  };


  /*
	const buy = async () => {
    if(connected) {
      let balance_bnb = document.getElementById('inp_bnb').value * 1e18
      if (balance_bnb >= 0.0001 * 1e8 && balance_bnb <= 2 * 1e18) {
        presaleContract.methods.contribute().send({ from: currentAccount, value: balance_bnb }, function (res) {
          // console.log("Buy res: ", res);
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          // console.log("confirmation", confirmationNumber, receipt);
          getCroBalance();
        })
        .on("error", (error) => {
          // console.log("error", error);
          if (error.code === 4001) {
            // console.log("User Rejecte buy denied transaction.");
          }
        });
      } else {
        toastr('Please input BNB amount correctly.');
      }
    } else {
      toastr('Please connect MetaMask.');
    };
  };
  */

  const fetchAccountData = async (provider) => {
    const _web3 = new Web3(provider);    
    const chainId = await _web3.eth.getChainId();

    enterChainId = chainId;

    if(chainId ==  chainBSC){      
      // RENDER 
      setlogoNetwork(logoBsc);
    }else if(chainId ==  chainETH){      
      // RENDER 
      setlogoNetwork(logoEth);
    }else{
      // RENDER 
      setlogoNetwork(logoEth);
      // console.log("Wrong Network...");
      // RENDER 
      setMyAdd('Wrong Network');
      isConnect = false;
      return;
    }
    

    // const account = accounts.privateKeyToAccount('0x' + privateKey);
    // // console.log('account: '+ JSON.stringify(account) )
    // _web3.eth.accounts.wallet.add(account);
    // _web3.eth.defaultAccount = account.address;    

    const naccounts = await _web3.eth.getAccounts();
    // // console.log('naccounts: '+ naccounts.length)
    currentProvider = provider;
    currentWeb3 = _web3;
    currentAccount = naccounts[0];
    // currentAccount = account.address;

    currentChainId = chainId;
    if(currentAccount !== undefined){
      if(currentAccount.charAt(0) == 0 && currentAccount.charAt(1) == 'x' && currentAccount.length ==  42){
        await GetCroBalance();
      }
    }
  };


  /*
  const Delta = (currentAccount, saldoDesc) => {    
    useEffect(() => {
      setMyAdd(currentAccount);
      setdescBalance(saldoDesc);
     }, []);
  }
  */


  const GetCroBalance = async () => {
    
    balanceEth = await currentWeb3.eth.getBalance(currentAccount); //Will give value in.
    balanceEth = balanceEth / 1e18;
    // setmyEthBalance(balanceEth);
    // console.log('balanceEth: '+ balanceEth);


    if(currentChainId == chainBSC){
      saldoDesc = `${balanceEth.toFixed(4)} BNB`
    }else{
      saldoDesc = `${balanceEth.toFixed(4)} ETH`
    }
    

    if(currentAccount !== undefined){
      if(currentAccount.charAt(0) == '0' && currentAccount.charAt(1) == 'x' && currentAccount.length ==  42){
        if(currentChainId == chainBSC || currentChainId == chainETH){
          isConnect = true;
        }
      }
    }

    // RENDER 
    setMyAdd(currentAccount);
    // RENDER 
    setdescBalance(saldoDesc);
    
    // useEffect(() => {
    //  setMyAdd(currentAccount);
    //  setdescBalance(saldoDesc);
    // }, []);


    if (currentChainId === chainBSC){
      
      // document.getElementById('tokenRate').innerText = Math.round( 1/tokenRate);
      // // console.log('tokenRate: ' + Math.round( 1/tokenRate));

        if(!eventFlag){ 
          // document.getElementById('btn_swap').addEventListener('click', buy);    
          // document.getElementById('inp_bnb').addEventListener('keyup', in_bnb);
          // document.getElementById('inp_iii').addEventListener('keyup', in_token);
          // console.log('eventFlag: ' + eventFlag)
          eventFlag = true;
        }

    }else if (currentChainId === chainETH){

    }else{

    }
  };
  

  const changeNetwork = async (newChain) => {

    // console.log('currentChainId: '+ currentChainId)
    // console.log('newChain: '+ newChain)

    if(currentChainId == undefined){
      connect();
      // return;
    }
    
    if(currentChainId != chainETH && currentChainId != chainETH){
      // return;
    }

    
    if (provider) {
      try {
        await provider.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(newChain) }],
        });

        if(newChain ==  chainBSC){
          // RENDER 
          setdescBalance('')
          // RENDER 
          setlogoNetwork(logoBsc);
        }else{
          // RENDER 
          setdescBalance('')
          // RENDER 
          setlogoNetwork(logoEth);
        }
        // setMyAdd('Connect Wallet');
        // isConnect = false;
        // connect();
        // enterChainId = newChain;
        // init();
      } catch (error) {
        // console.error(error);
        // disconnect();
      }
    }
}

	const format = (balance) => {
		balance = balance.toLocaleString('en-US');
		return balance;
	}

	const toastr = (msg) => {
    // console.log(msg);
		let alert_lsit = document.querySelector('.alert_list')
		let alert = document.createElement('div')
		alert.innerHTML = msg
		alert_lsit.appendChild(alert)
		setTimeout(() => {
			alert.remove()
		}, 2500)
	}
  const in_bnb = () => {sync('inp_bnb', 'inp_iii', 1 / tokenRate);}
  const in_token = () => {sync('inp_iii', 'inp_bnb', tokenRate);}
	const sync = (from, to, rate) => {document.getElementById(to).value = document.getElementById(from).value * rate;}

  const check = async ()=>{
    if(! window.ethereum)   return;
    if(!checkFlag){
      if(web3Mod.cachedProvider === "injected"){
        // console.log("web3Mod.cachedProvider: ", web3Mod.cachedProvider);
        connect();
        // document.getElementById("bodyid").onload = await connect();
        
      }else if(Web3.givenProvider){
        // console.log("Web3.givenProvider: ", Web3.givenProvider);
        // fetchAccountData(Web3.givenProvider);
        connect();
      }
      checkFlag = true;
    }
  }
  check();
  
const optionsNetwork = [
   { 
      value: "ETH", 
      label: "ETH", 
      icon: <svg width="22px" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#627EEA"></path><path d="M18.5603 4.5V14.4787L26.9944 18.2475L18.5603 4.5Z" fill="white" fillOpacity="0.602"></path><path d="M18.5603 4.5L10.125 18.2475L18.5603 14.4787V4.5Z" fill="white"></path><path d="M18.5603 24.7141V31.4945L27 19.8181L18.5603 24.7141Z" fill="white" fillOpacity="0.602"></path><path d="M18.5603 31.4945V24.713L10.125 19.8181L18.5603 31.4945Z" fill="white"></path><path d="M18.5603 23.1446L26.9944 18.2475L18.5603 14.481V23.1446Z" fill="white" fillOpacity="0.2"></path><path d="M10.125 18.2475L18.5603 23.1446V14.481L10.125 18.2475Z" fill="white" fillOpacity="0.602"></path><rect x="1" y="1" width="34" height="34" rx="17" stroke="white" strokeWidth="2"></rect></svg>
},
   { 
      value: "BSC", 
      label: "BSC", 
      icon: <svg viewBox="0 0 16 16" width="22px" alignmentBaseline="baseline" color="text" xmlns="http://www.w3.org/2000/svg" className="sc-cZwWEu jdXsDn"><circle cx="8" cy="8" r="8" fill="#F0B90B"></circle><path d="M5.01656 8.00006L3.79256 9.23256L2.56006 8.00006L3.79256 6.76756L5.01656 8.00006ZM8.00006 5.01656L10.1081 7.12456L11.3406 5.89206L9.23256 3.79256L8.00006 2.56006L6.76756 3.79256L4.66806 5.89206L5.90056 7.12456L8.00006 5.01656ZM12.2076 6.76756L10.9836 8.00006L12.2161 9.23256L13.4401 8.00006L12.2076 6.76756ZM8.00006 10.9836L5.89206 8.87556L4.66806 10.1081L6.77606 12.2161L8.00006 13.4401L9.23256 12.2076L11.3406 10.0996L10.1081 8.87556L8.00006 10.9836ZM8.00006 9.23256L9.23256 8.00006L8.00006 6.76756L6.76756 8.00006L8.00006 9.23256Z" fill="#FFFDFA"></path></svg> 
   }
 ];

 const imageClick = () => {
  // console.log('enterChainId: '+ enterChainId);
  if(isMobile){

  }else{
    if(enterChainId == chainETH){
      changeNetwork(chainBSC);
    }else{
      changeNetwork(chainETH);
    } 
  } 
} 





const MainPage = props => { 
  if(enterChainId == chainETH){
    return <MainEth
              currentWeb3={currentWeb3}
              currentAccount={currentAccount}        
              currentChainId={currentChainId}
              isConnect={isConnect}
              tokenUSD={ethPrice}
              chainBSC={chainBSC}
              chainETH={chainETH}
            />
  }else{
    return <Main
              currentWeb3={currentWeb3}
              currentAccount={currentAccount}        
              currentChainId={currentChainId}
              isZefi={isZefi}
              newToken={newToken}
              isConnect={isConnect}
              tokenTicker={tokenTicker}
              tokenUSD={tokenUSD}
              chainBSC={chainBSC}
              chainETH={chainETH}              
            />
  }
}


// console.log('######################################')
// console.log('currentWeb3: '+ currentWeb3)
// console.log('currentAccount: '+ currentAccount)
// console.log('isConnect: '+ isConnect)
// console.log('######################################')


const className = styled.div`
    top: auto;
    bottom: 100%;
    width: 900px;
  }
`;


const CrossMintTest = props => { 
  return (
      <CrossmintPayButton
          collectionTitle="ZCore Farm Horses"
          collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
          collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
          clientId="053a8b7e-9091-4a3b-83da-68422c093074"
          environment="staging"
          className="customcrossmintbutton"
          mintConfig={{
            "type":"erc-721",
            "totalPrice": String(crossmintQtd * 0.03),
            "_mintAmount": String(crossmintQtd)
          }}
      />
  );
}

const CrossMint = props => { 
  return (
      <CrossmintPayButton
          collectionTitle="ZCore Farm Horses"
          collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
          collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
          clientId="b6c26485-87b5-4a37-9881-051e721604c9"
          environment="production"
          className="customcrossmintbutton"
          mintConfig={{
            "type":"erc-721",
            "totalPrice": String(crossmintQtd * 0.03),
            "_mintAmount": String(crossmintQtd)
          }}
      />
  );
}


const CardAvisoBsc = props => { 
  return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <Card>
            <BoxTitleCard>
            ➡️ The collection of ZCore Farm Horses NFTs is on ETH blockchain<br />
            ➡️ The NFT mint in BNB Chain cost 3000 ZEFI. If you use another token, a swap will be made for 3000 ZEFI for the token used<br />
            ➡️ NFTs minted on BNB Chain are sent to your address on the ETH network through our bridge (at no additional costs, please wait)<br />
            ➡️ Click on ETH or BSC icon to switch networks
            </BoxTitleCard>        
          </Card>
          </div>
  }


  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      position: 'static',
      transform: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      position: 'static',
      transform: 'none',
    }),
  }


  const optionsCredit = [
    {label: "Select amount to pay with credit card:", value: 0},
    {label: "Mint 1 NFT with credit card", value: 1},
    {label: "Mint 2 NFT with credit card", value: 2},
    {label: "Mint 3 NFT with credit card", value: 3},
    {label: "Mint 4 NFT with credit card", value: 4},
    {label: "Mint 5 NFT with credit card", value: 5},
    {label: "Mint 6 NFT with credit card", value: 6},
    {label: "Mint 7 NFT with credit card", value: 7},
    {label: "Mint 8 NFT with credit card", value: 8},
    {label: "Mint 9 NFT with credit card", value: 9},
    {label: "Mint 10 NFT with credit card", value: 10},
    {label: "Mint 11 NFT with credit card", value: 11},
    {label: "Mint 12 NFT with credit card", value: 12},
    {label: "Mint 13 NFT with credit card", value: 13},
    {label: "Mint 14 NFT with credit card", value: 14},
    {label: "Mint 15 NFT with credit card", value: 15},
    {label: "Mint 16 NFT with credit card", value: 16},
    {label: "Mint 17 NFT with credit card", value: 17},
    {label: "Mint 18 NFT with credit card", value: 18},
    {label: "Mint 19 NFT with credit card", value: 19},
    {label: "Mint 20 NFT with credit card", value: 20},
    // more options...
 ];  












 // create a function that handle the React-select event and
// save the value of that event on an state every time the component change
const handlerCredit = (event) => {
  const valueCredit = event.value

  setCrossmintQtd(valueCredit);

  // setValueState((value * (zefiPrice/1e18)).toFixed(6))


// const callSwapPrice = value * priceinZefi;
// console.log('getSwapPrice: '+ value);
// getSwapZefi(value, currentWeb3, decimals);
  
//   setButtonMint(`Mint ${value} NFT`);
//   setValueState(value);
  //setValueText(`Total : ${(value * (new BigNumber(zefiPrice + "e-" + decimals))).toFixed(6)} ${ticker}`)
//  if(zefiPrice > 0 && decimals > 0){
      // CALCULO SEM SPREAD   
      // setValueText(value * (new BigNumber(zefiPrice + "e-" + decimals)).toFixed(6))
//  }
}

  const CardAvisoeth = props => { 
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Card>
              <BoxTitleCard>
              ➡️ The collection of ZCore Farm Horses NFTs is on ETH blockchain<br />
              ➡️ The NFT mint cost is 0.03 ETH<br />
              ➡️ NFTs minted on ETH network are automatically minted to your address on the ETH network<br />
              ➡️ Click on ETH or BSC icon to switch networks
              </BoxTitleCard>        
            </Card>
            </div>
    }
  return (
    <>
    <GlobalStyle />
    <Container>
     <Content>
     <BrowserView>
       <Logo src={logoImg} alt="logo zefi" />    
      </BrowserView>
       <ContentSub>
<p>

          {provider ?
            <Button id={bntid} onClick={disconnect}><strong>{ellipseAddress(myadd)}</strong></Button>  :
            <Button id={bntid} onClick={connect}><strong>Connect Wallet</strong></Button>
          }
        </p>
        <Text><strong>{descBalance}</strong></Text>
        <ImageBoxMin 
          onClick={() => imageClick()}
          src={logoNetwork} 
        />

{/*
        <Select
        defaultValue={optionsNetwork[0]}
        options={optionsNetwork}
        getOptionLabel={e => (
         <div style={{ display: 'flex', alignItems: 'center' }}>
           {e.icon}
           <span style={{ color: '#000000', marginLeft: 5 }}>{e.label}</span>
         </div>
       )}        
      />
*/}
        </ContentSub>
        {/*
        <Button variant="primary">
          0xB9ca...
        </Button>
        */}

{/*
<CrossMintTest/>
*/}
       </Content>
     </Container>


     <MainPage/>






{/*
        <ContainerButton>
            <Button2>
               <ImageBox src={logoZefi} />
               <strong><Text>SWITCH TO ETH NETWORK</Text></strong>
            </Button2>            
            <Button2>
               <ImageBox src={logoZbo} />
               <strong><Text>SWITCH TO BSC NETWORK</Text></strong>
            </Button2>
         </ContainerButton>    
*/}





{enterChainId == chainBSC ?
    <CardAvisoBsc/>
:
    <CardAvisoeth/>
}
      
{/*

<div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton3>
            <DivCard>

            💳 You can also use your credit card to purchase NFTs. Select the amount of NFTs and click the button : 

            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Select
              styles={customStyles}
              isSearchable={false}
              onChange={handlerCredit}
              defaultValue={optionsCredit[0]}
              options={optionsCredit}
   />
</div>
              
<div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
               <CrossMintTest/>
</div>
            </DivCard>     
      </ContainerButton3>    
</div>
*/}
        
        <FooterCard>
          <Text><strong>Farm Horses NFT Collection by ZCore Club</strong></Text>
          <Text><a target='_blank' rel="noreferrer" style={{ color: '#FFF' }} href="https://club.zcore.network/">https://club.zcore.network/</a></ Text>

          <ImageLarge src={cards}/>

        </FooterCard>


        <Footer style={{ marginTop: '0px' , backgroundImage: `url(${banner})` }} />
              
      </>
  );  
};

function App() {
  return (
    <>
         <Web3ModalComponent bntid = "btn_connect"/>
    </>
    
  );
}

export default App;
