import React, { useState, useEffect, useMemo } from "react";
import Select, { components } from "react-select";
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from "styled-components";
import buyEthABI from "../../contract/abi/buyEthABI";
import whiteListABI from "../../contract/abi/whiteListABI";
import ZCRStakingContractABI from "../../contract/abi/ZCRStakingContractABI";
import { SpinnerCircularSplit } from 'spinners-react';
import { ToastContainer, toast } from 'react-toastify';
import BigNumber from "bignumber.js";
import { tokens , tokensCake } from "../../helpers/tokens";
import { Container, Row, Col } from 'react-grid-system';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { zefiAddress , zboAddress, buyBscAddress, buyEthAddress, infinityAmmount, opensea, mintStart, whiteStop, whiteListEth, stakingNft, nextSnapshot } from "../../contract/contracts";
import { FaExternalLinkAlt, FaHandHoldingUsd, FaPlusCircle, FaVoteYea, FaUniversity } from "react-icons/fa";
import Countdown from 'react-countdown';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';





import 'react-toastify/dist/ReactToastify.css';

import {
   ContainerMainMinus,
   ContainerMain,
   ContainerTop,
   Card,
   ImageBox,
   Image,
   ImageContainer,
   Button,
   Button2,
   Button3,
   Button4,
   DivCard,
   BoxTitleContainer,
   BoxTitle,
   BalanceConteiner,
   BalanceContent,
   StatisticsContainer,
   StatisticsContent,
   imgright,
   Text,
   TextPrice,
   ContainerButton,
   ContainerButton2,
   ContainerMainMin,
   ContainerButton3,
   Button5,
   Button6,
   ImageBoxMax,
   TextBalance,
   ContainerPrinc,
   ContainerTopMin,
   TextGray,
   ColButton,
   ColLat,
   ColLatMax,
   ContainerMainSelect,
   CardSelect,
   ButtonNada,
   ImageBlank,
   ImageLarge,
   DivWhiteList
} from "./style";

import logoZefi from '../../assets/zefi.png';
import logoPix from '../../assets/pix.png';
import logoEth from '../../assets/eth.png';
import logoZbo from '../../assets/zbo.svg';

import cards from '../../assets/cards.png';
import horse from '../../assets/horse.png';
import p1 from '../../assets/p1.png';
import p2 from '../../assets/p2.png';
import p3 from '../../assets/p3.png';
import p4 from '../../assets/p4.png';
import p5 from '../../assets/p5.png';
import p6 from '../../assets/p6.png';
import p7 from '../../assets/p7.png';
import p8 from '../../assets/p8.png';
import p9 from '../../assets/p9.png';

import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';

import preview from '../../assets/promom.gif';
// import preview from '../../assets/reveral.svg';
import unknowntoken from '../../assets/unknown-token.png';
import { DetailsList } from "../DetailsList";
import { ConsoleView } from "react-device-detect";
import coingecko from "../../helpers/coingecko";




/*
const min = 1;
const max = 9;
const rand =  Math.floor(Math.random() * max) + min ;
// console.log('rand: '+rand)
let preview;
if(rand == 1){
   preview = p1;
}else if(rand == 2){
   preview = p2;
}else if(rand == 3){
   preview = p3;
}else if(rand == 4){
   preview = p4;
}else if(rand == 5){
   preview = p5;
}else if(rand == 6){
   preview = p6;  
}else if(rand == 7){
   preview = p7;               
}else if(rand == 8){
   preview = p8;               
}else{
   preview = p9;
}
*/

const decimals = 18;

// const zefiAddress = '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07';  // ZEFI
// const zboAddress = '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8';  // ZBO
// const buyBscAddress = '0x2CA5fDc1Cd421beAf1d83f199f3F5d407CF82F9e';
// const infinityAmmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const LoadingDiv = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;



export const ContainerButton4 = styled.div`

margin: 0px;

@media (max-width: 900px) {
  /*
   display: flex;
  flex-direction: row;
  */
}
`;


export const DivCard4 = styled.div`
 max-width: 480px;
 padding: 8px;
 margin: 0px;

 justify-content: center;
 align-items: center;
 text-align: center;

 background-color: #f3fbf0;
 box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
 color: #000000;
 border:  0px solid;
 border-radius: 10px;

 @media (max-width: 900px) {
  width: 100%;
  padding: 8px;
  margin: 0px;
 }

`;


export const ContainerButton5 = styled.div`
width: 100%;
max-width: 640px;
margin: 15px 0px 15px 0px;

display: flex;

justify-content: center;
align-items: center;
text-align: center;

@media (max-width: 900px) {
   display: inline-flex;
   margin: 10px 0px 10px 0px;
  /*
  max-width: 320px;
   display: flex;
  flex-direction: row;
  */
}
`;

export const DivCard5 = styled.div`
 width: 50%;
 padding: 8px;
 margin: 5px;

 justify-content: center;
 align-items: center;
 text-align: center;

 background-color: #00414a;
 box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
 color: #FFFFFF;
 border:  0px solid;
 border-radius: 10px;
 font-weight: bold;
 text-transform: uppercase;

 cursor: pointer;

 @media (max-width: 900px) {
   width: 240px;
   margin: 10px;
   padding: 8px;
   /*
   width: 50%;
  padding: 8px;
  margin: 0px;
  */
 }

`;

const ColoredLine = ({ color }) => (
   <hr
       style={{
          marginTop: '10px',
          marginBottom: '10px',
           color: color,
           backgroundColor: color,
           height: 3
       }}
   />
);


export const BuyBox = styled.div`  
  margin-top: 20px;
`;


export function MainEth ({ currentWeb3, currentAccount, currentChainId, isConnect, tokenUSD, chainBSC, chainETH}) {   

// FORCE   
const [saleIsActive, setSaleIsActive] = useState(true)
const [mintEnabled, setMintEnabled] = useState(true)

const [iswhitelist, setIswhitelist] = useState(true)
const [zefiPrice, setzefiPrice] = useState(0)
const [loadButton, setloadButton] = useState(false)
const [supplyNft, setSupplyNft] = useState(0)
const [myNFT, setMyNFT] = useState(0)
const [nftList, setNftList] = useState([])
const [maxHorses, setmMxHorses] = useState(0)
const [availableRewards, setAvailableRewards] = useState(0)
const [buttonMint, setButtonMint] = useState('Mint NFT')

const [stakeapproved, setStakeApproved] = useState(true)
const [optionsStaked, setOptionsStaked] = useState([])
const [optionsNoStaked, setOptionsNoStaked] = useState([])
const [stakingOwner, setstakingOwner] = useState([])


const handlerStaked = (event) => {   
   console.log(event)
   // const value = event.value
   setValueWithdraw(event)
}

const handlerNoStaked = (event) => {
   const value = event.value
   console.log(value)
   setValueDeposit(event)
}

const [valueWithdraw, setValueWithdraw] = useState([])
const [valueDeposit, setValueDeposit] = useState([])


const [loadButtonClaim, setloadButtonClaim] = useState(false)
const [loadButtonWithdraw, setloadButtonWithdraw] = useState(false)
const [loadButtonDeposit, setloadButtonDeposit] = useState(false)
const [amountStaked, setAmountStaked] = useState(0)
const [unclaimedRewards, setUnclaimedRewards] = useState(0)
const [totalNFT, setTotalNFT] = useState(0)

const [valueState,setValueState] = useState(0)
// create a function that handle the React-select event and
// save the value of that event on an state every time the component change
    const handler = (event) => {
        const value = event.value
        // setValueState((value * (zefiPrice/1e18)).toFixed(6))


      // const callSwapPrice = value * priceinZefi;
      // console.log('getSwapPrice: '+ value);
      getSwapZefi(value, currentWeb3, decimals);
        
         setButtonMint(`Mint ${value} NFT`);
         setValueState(value);
        //setValueText(`Total : ${(value * (new BigNumber(zefiPrice + "e-" + decimals))).toFixed(6)} ${ticker}`)
        if(zefiPrice > 0 && decimals > 0){
            // CALCULO SEM SPREAD   
            // setValueText(value * (new BigNumber(zefiPrice + "e-" + decimals)).toFixed(6))
        }
}


const funcGetAllowanceStake = async () => {

   // console.log('funcGetAllowance: start!')
   if(currentWeb3){
      // console.log('funcGetAllowance: run...')
      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);    

         await buyEthContract.methods.isApprovedForAll(currentAccount, stakingNft).call().then(function (allowance) {
           // console.log('allowance ZEFI: ' + allowance);
              setStakeApproved(allowance)
         });
   }   
}

const [swapTotal,setSwapTotal] = useState(0)
const [priceUnique,setpriceUnique] = useState(0)



const [crossmintQtd, setCrossmintQtd] = useState(0);

const CrossMintTest = props => { 
  if(crossmintQtd > 0){
   return (
      <CrossmintPayButton
          collectionTitle="ZCore Farm Horses"
          collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
          collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
          clientId="053a8b7e-9091-4a3b-83da-68422c093074"
          environment="staging"
          className="customcrossmintbutton"
          mintConfig={{
            "type":"erc-721",
            "totalPrice": String(crossmintQtd * 0.03),
            "_mintAmount": String(crossmintQtd)
          }}
      />
         );
   }else{
    return <></>;
   }
}

const CrossMint = props => { 
   if(crossmintQtd > 0){
  return (
      <CrossmintPayButton
          collectionTitle="ZCore Farm Horses"
          collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
          collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
          clientId="b6c26485-87b5-4a37-9881-051e721604c9"
          environment="production"
          className="customcrossmintbutton"
          mintConfig={{
            "type":"erc-721",
            "totalPrice": String(crossmintQtd * 0.03),
            "_mintAmount": String(crossmintQtd)
          }}
      />
  );
}else{
   return <></>;
  }
}

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    position: 'static',
    transform: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    position: 'static',
    transform: 'none',
  }),
}


const optionsCredit = [
  {label: "Select quantity to pay with credit card:", value: 0},
  {label: "Mint 1 NFT with credit card", value: 1},
  {label: "Mint 2 NFTs with credit card", value: 2},
  {label: "Mint 3 NFTs with credit card", value: 3},
  {label: "Mint 4 NFTs with credit card", value: 4},
  {label: "Mint 5 NFTs with credit card", value: 5},
  {label: "Mint 6 NFTs with credit card", value: 6},
  {label: "Mint 7 NFTs with credit card", value: 7},
  {label: "Mint 8 NFTs with credit card", value: 8},
  {label: "Mint 9 NFTs with credit card", value: 9},
  {label: "Mint 10 NFTs with credit card", value: 10},
  {label: "Mint 11 NFTs with credit card", value: 11},
  {label: "Mint 12 NFTs with credit card", value: 12},
  {label: "Mint 13 NFTs with credit card", value: 13},
  {label: "Mint 14 NFTs with credit card", value: 14},
  {label: "Mint 15 NFTs with credit card", value: 15},
  {label: "Mint 16 NFTs with credit card", value: 16},
  {label: "Mint 17 NFTs with credit card", value: 17},
  {label: "Mint 18 NFTs with credit card", value: 18},
  {label: "Mint 19 NFTs with credit card", value: 19},
  {label: "Mint 20 NFTs with credit card", value: 20},
  // more options...
];  


// create a function that handle the React-select event and
// save the value of that event on an state every time the component change
const handlerCredit = (event) => {
   const valueCredit = event.value
   console.log(valueCredit);
   setCrossmintQtd(valueCredit);
}


const getSupply = async () => {
   try {
   if(currentWeb3){
      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      await buyEthContract.methods.totalSupply().call().then(function (response) {
         // console.log('totalSupply: ' + response);
         setSupplyNft(response);
     });

      await buyEthContract.methods.maxSupply().call().then(function (response) {
         // console.log('maxSupply: ' + response);
         setmMxHorses(response);
      });
   }
} catch (e) {
}
}  

 useEffect(() => {
   getSupply();
  }, [currentWeb3]);

/*
const funcGetPriceUsd = async () => {
   if(currentWeb3){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      await buyBscContract.methods.getPriceUsd(zefiAddress, '1000000000000000000').call().then(function (response) {
         
         temp_setTokenUSD = response/1e18;
         setTokenUSD(response/1e18);

         // console.log('tokenPriceUSD: ' + tokenUSD);
      });   
   }   
}
*/



const aproveContractStaking = async () => {
   if(currentWeb3){
      setloadButton(true);

      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      await buyEthContract.methods.setApprovalForAll(stakingNft, 'true').send({from:currentAccount}).then((tx)=>{
         setloadButton(false);
         setStakeApproved(true);
         toast.success(`🐎 Contract approved! Now you can stake NFT...`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         setloadButton(false);
         // setStakeApproved(false);
      })     
      
   }
}


const getOwners = async () => {
   // console.log(currentWeb3);
   if(currentWeb3 && nftList.length == 0){
   try {
        let response = await fetch('https://zcorefinance.app/nft_owners_read');
        let responseJson = await response.json();
        let arrNftList = []; //copy array by value
        let mtotalnft = 0;
        for (var i = 0; i < responseJson.length; i++)
         {
            var obj = responseJson[i];
            if(obj.owner_of.toLowerCase() == currentAccount.toLowerCase()){

               mtotalnft++;
               let imagem;
               let nome;
               if(obj.game_item == 1){
                  nome = 'Casa: ' + JSON.parse(obj.game_info).local;
                  if(JSON.parse(obj.game_info).apto != '0'){
                    nome = 'Apto: ' + JSON.parse(obj.game_info).apto;
                  }
                  imagem = 'https://zcorefinance.app/houses/'+ JSON.parse(obj.game_info).local +'.jpg';
                }
                if(obj.game_item == 2){
                  nome = 'Veículo'
                  imagem = 'https://zcorefinance.app/game_veiculos_foto?hash='+ JSON.parse(obj.game_info).model;
                }              
                if(obj.game_item == 99){
                  nome = 'Mansão'
                  imagem = 'https://cdn.discordapp.com/attachments/1025144724983644160/1025871096647528589/nophoto.jpg';
                }               
               
               arrNftList.push(
                  {
                     id: Number(obj.id), 
                     score: obj.score,
                     rank: obj.rank,
                     nome: nome,
                     imagem: imagem,                     
                  }
                  )
               // console.log(`Owner: ${obj.owner_of}`);
            }         
         }
         setMyNFT(mtotalnft);
         setNftList(arrNftList);
         // console.log(arrNftList);
    } catch(error) {
     // console.error(error);
   }
   }
 }

 const Getownersrender = () => {
   if(nftList.length == 0){
      return <></>
   }else{
      return <Container>
         <Row>
         {
            nftList.map(({ id, score, rank, nome, imagem }) => <Col style={{marginTop: '20px'}} key={id}><div style={{ textAlign: 'center' }}>
         <a target='_blank' rel="noreferrer" href={`https://mynft.zcore.network/${id}`}><Image style={{
          width: 200,
          height: 200,
          borderRadius: '20%',
          overflow: 'hidden',
        }} width={200} height={200} src={`https://files.zcore.network/assets/${id}.webp`} /></a>
      <Image style={{
          marginTop: '10px',
          width: 192,
          height: 108,
          borderRadius: '15%',
          overflow: 'hidden',     
        }} 
        src={imagem} />        
               </div>
                  <Row>
                     <Col><strong><Text style={{ textAlign: 'center', color: '#00414a' , fontSize: '18px' }} >#{id}<br /><small>{nome}</small></Text></strong></Col>
                  </Row>
                  <Row>
                     <Col><Text style={{ textAlign: 'center', color: '#adadad' , fontSize: '14px' }} >Rank: {rank}</Text></Col>
                     <Col><Text style={{ textAlign: 'center', color: '#adadad' , fontSize: '14px' }} >Score: {score}</Text></Col>                  
                  </Row>
                  <Row>
                  <Col style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }} md={12}><Button2><a target='_blank' style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" href={`https://api.opensea.io/api/v1/asset/0x9c9004de368b1968c953a5022271de80310489e4/${id}/?force_update=true`}><strong>Refresh Metadata</strong></a></Button2></Col>
                  <Col style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }} md={12}><Button2><a target='_blank' style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" href={`https://opensea.io/assets/ethereum/0x9c9004de368b1968c953a5022271de80310489e4/${id}`}><strong>Open in Opensea</strong></a></Button2></Col>
                  </Row>
               </Col>)
         }
         </Row>
         </Container>
   }
 };


const getMyNFTs = async () => {
   if(currentWeb3 && isConnect){
   const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
   await buyEthContract.methods.balanceOf(currentAccount).call().then(function (response) {
      // console.log('isWhiteListed (isConnect): ' + isConnect);
      // console.log('isWhiteListed: ' + response);
      // console.log('isWhiteListed: ' + currentAccount);
      //if(response == true){
         setMyNFT(response);
         console.log('my NFTS myNFT : ' + myNFT);
         // console.log('my NFTS myNFT : ' + response);
         if(response > 0){
            getMyNFTList();
         }
         getMyStaking();
      //}else{
      //   setMyNFT(false);
      //}         
   });
   }
}




const getMyNFTList = async () => {
   if(currentWeb3 && isConnect){
      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      await buyEthContract.methods.walletOfOwner(currentAccount).call().then(function (response) {
         // console.log('my NFTS LIST: ' + response);
         let tempList = []
         for(let i = 0; i < response.length; i++){ 
            // console.log(response[i]);
            tempList.push(
               {
                  label: '#'+response[i],
                  value: Number(response[i]),
                  index: i
                }
            )
         }
         // console.log(tempList.length)
         if(tempList.length > 0){
            setOptionsNoStaked(tempList)
         }
         
      });
   }
}



const getMyStaking = async () => {
   if(currentWeb3 && isConnect){
   funcGetAllowanceStake();
   const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);    
   const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress);    

   
   await stakingContract.methods.getStakedTokensID(currentAccount).call().then(function (response) {
      let tempList = []
      // for(let i = 0; i < response.length; i++){ 
            // const newList = Array.from(response[i]);
            // console.log('getStakedTokens : ' + response);
            if(response > 0){
               tempList.push(
                  {
                     label: '#'+ response,
                     value: Number(response),
                     index: 0
                   }
               )            
            }
      // }      
      setOptionsStaked(tempList)
   });
   
      
   await stakingContract.methods.stakers(currentAccount).call().then(function (response) {
      // console.log('my NFTS amountStaked : ' + response['amountStaked']);
      // console.log('my NFTS timeOfLastUpdate : ' + response['timeOfLastUpdate']);
      // console.log('my NFTS unclaimedRewards : ' + response['unclaimedRewards']);
      setAmountStaked(Number(response['amountStaked']));
      setUnclaimedRewards(Number(response['unclaimedRewards']));

      const total = Number(response['amountStaked']) + Number(myNFT);
      // console.log('my NFTS myNFT total : ' + myNFT);
      // console.log('my NFTS myNFT total : ' + total);
      setTotalNFT(total);
      
   });


   await stakingContract.methods.availableRewards(currentAccount).call().then(function (response) {
      const resp = response/1e18;
      // console.log('availableRewards: ' + resp.toFixed(6));      
      setAvailableRewards(resp.toFixed(6))
   });


   await buyEthContract.methods.walletOfOwner(stakingNft).call().then(function (response) {
      // console.log('staking NFT LIST: ' + response);
      if(response.length > 0){
         setstakingOwner(response)
      }else{
         setstakingOwner([])
      }
      // console.log(response.length)
      // if(response.length > 0){
      //    setstakingOwner(response)
      // }      
   });   


   }
}



const funcGetPriceZefi = async () => {

   try {
   if(currentWeb3 && isConnect && currentChainId == chainETH){
      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);       
      const whiteListEthContract = new currentWeb3.eth.Contract(whiteListABI, whiteListEth); 

/*
      await buyEthContract.methods.whitelistMintEnabled().call().then(function (response) {
         // console.log('whitelistMintEnabled: ' + response);
         if(response == true){
            // setWhitelistMintEnabled(true);
         }else{
            // setWhitelistMintEnabled(false);
         }
         
      });
*/








/*
      await whiteListEthContract.methods.isUserWhiteListed(currentAccount).call().then(function (response) {
         // console.log('isWhiteListed (isConnect): ' + isConnect);
         // console.log('isWhiteListed: ' + response);
         // console.log('isWhiteListed: ' + currentAccount);
         if(response == true){
            setIswhitelist(true);
         }else{
            setIswhitelist(false);
         }         
      });

      */

/*
      await buyEthContract.methods.paused().call().then(function (response) {
         // console.log('paused: ' + response);
         if(response == true){
            setSaleIsActive(false);
         }else{
            setSaleIsActive(true);
         }         
   });
*/   

      await buyEthContract.methods.cost().call().then(function (response) {

            // console.log('zefiPrice: ' + zefiPrice);
            // console.log('priceUnique: ' + priceUnique);
            // console.log('priceUnique: ' + tokenUSD);

            setpriceUnique(new BigNumber(response + "e-" + decimals) * tokenUSD)
            setzefiPrice(response);
      });
   }

} catch (e) {
}

}


const getSwapZefi = async (valor, currentWeb3, decimals) => {
   if(currentWeb3 && valor > 0 && decimals > 0){
      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 
      // console.log('getSwapPrice: ' + valor);
      await buyEthContract.methods.cost().call().then(function (response) {
         // console.log('getSwapPrice: ' + (response*valor));
         setSwapTotal((new BigNumber((response*valor) + "e-" + decimals)).toNumber().toFixed(6));
      });   
   }
}





const buyWithEth = async (amount) => {
   if(currentWeb3){

      // const enableSwap = (new BigNumber(tokenBalance).toNumber()  >= new BigNumber(swapTotal).toNumber()) ? true : false;
      const enableSwap = true;
      
      // console.log('buyWithZefi: ' + amount);    
      // console.log('buyWithZefi: ' + enableSwap);
      // console.log('buyWithZefi: ' + (new BigNumber(swapTotal * 1e18)));


      if(enableSwap){
         // console.log('buyWithZefi: GO!');
         setloadButton(true);     


      const buyEthContract = new currentWeb3.eth.Contract(buyEthABI, buyEthAddress); 

      await buyEthContract.methods.mint(amount).send(
         {
            from:currentAccount,
            value: (new BigNumber(swapTotal * 1e18))
         }
         ).then((tx)=>{
         //action prior to transaction being mined
         // console.log('comprou -----------------------> ' + tx)
         setloadButton(false);
         getSupply();
         // getZefiPrice();
         toast.success(`🐎 ${amount} NFT minted!`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         //action to perform when user clicks "reject"
         setloadButton(false);
      })       



   }else{
      toast.error(`🐎 Insufficient balance...`)
   }
   }
}



const enterWhiteList = async () => {
   if(currentWeb3){      

      // console.log('addAddressToWhitelist: GO!');
      // console.log('addAddressToWhitelist: '+ currentAccount);
      setloadButton(true);     

      const whiteListEthContract = new currentWeb3.eth.Contract(whiteListABI, whiteListEth); 
      await whiteListEthContract.methods.addAddressToWhitelist().send(
         {
            from:currentAccount,
         }
         ).then((tx)=>{
         //action prior to transaction being mined
         // console.log('entrou na whitelist -----------------------> ' + tx)
         setloadButton(false);
         setIswhitelist(true);
         toast.success(`🐎 You joined the Whitelist! Wait date for mint.`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         //action to perform when user clicks "reject"
         setloadButton(false);
      })
   }
}



const buyZefi = async () => {
   if(currentWeb3){
         // console.log(`Mint: ${valueState}`)   
         if(valueState == 0){
            toast.warn("🐎 Select NFT quantity to mint!")
         }else{
            buyWithEth(valueState)
         }
   }
}





const clickUnstake = async () => {
   if(currentWeb3){
         
         if(valueWithdraw.length == 0){
            toast.warn("🐎 Select NFT to unstake!")
         }else{

            setloadButtonWithdraw(true);
      
            const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);
            await stakingContract.methods.withdraw(valueWithdraw.value).send({from:currentAccount}).then((tx)=>{
               setloadButtonWithdraw(false);
               // setStakeApproved(true);
               toast.success(`🐎 NFT withdrawn!`)
               setValueWithdraw([]);
               getMyNFTs();
            })
            .catch(()=>{
               // console.log('rejeitou...')
               setloadButtonWithdraw(false);
               // setStakeApproved(false);
            })                
            
         }
      }else{
         toast.warn("Connect your wallet!")
      }
}


const clickDeposit = async () => {
   if(currentWeb3){
         
         if(valueDeposit.length == 0){
            toast.warn("🐎 Select NFT to stake!")
         }else{

            setloadButtonDeposit(true);
      
            const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);
            await stakingContract.methods.stake(valueDeposit.value).send({from:currentAccount}).then((tx)=>{
               setloadButtonDeposit(false);
               // setStakeApproved(true);
               toast.success(`🐎 NFT deposited!`)
               setValueDeposit([]);
               getMyNFTs();
            })
            .catch(()=>{
               // console.log('rejeitou...')
               setloadButtonDeposit(false);
               // setStakeApproved(false);
            })                
            
         }
      }else{
         toast.warn("Connect your wallet!")
      }
}


const clickDepositAlert = async () => {
   toast.warn("Only 1 NFT stake per wallet is allowed!")
}

const clickClaim = async () => {
   if(currentWeb3){
            setloadButtonClaim(true);
      
            const stakingContract = new currentWeb3.eth.Contract(ZCRStakingContractABI, stakingNft);
            await stakingContract.methods.claimRewards().send({from:currentAccount}).then((tx)=>{
               setloadButtonClaim(false);
               // setStakeApproved(true);
               toast.success(`🐎 Rewards claimed!`)
               getMyNFTs();
            })
            .catch(()=>{
               // console.log('rejeitou...')
               setloadButtonClaim(false);
               // setStakeApproved(false);
            })     
      
   }else{
      toast.warn("Connect your wallet!")
   }
}


const clickApprove = async () => {
   if(currentWeb3){
      aproveContractStaking();
         /*
         if(valueState == 0){
            toast.warn("🐎 Select NFT quantity to mint!")
         }else{
            buyWithEth(valueState)
         }
         */
      }else{
         toast.warn("Connect your wallet!")
      }
}

 const options = [
   {label: "Select NFT quantity:", value: 0},
   {label: "1 NFT", value: 1},
   {label: "2 NFTs", value: 2},
   {label: "3 NFTs", value: 3},
   {label: "4 NFTs", value: 4},
   {label: "5 NFTs", value: 5},
   {label: "6 NFTs", value: 6},
   {label: "7 NFTs", value: 7},
   {label: "8 NFTs", value: 8},
   {label: "9 NFTs", value: 9},
   {label: "10 NFTs", value: 10},
   {label: "11 NFTs", value: 11},
   {label: "12 NFTs", value: 12},
   {label: "13 NFTs", value: 13},
   {label: "14 NFTs", value: 14},
   {label: "15 NFTs", value: 15},
   {label: "16 NFTs", value: 16},
   {label: "17 NFTs", value: 17},
   {label: "18 NFTs", value: 18},
   {label: "19 NFTs", value: 19},
   {label: "20 NFTs", value: 20},
   // more options...
];



// getData();
// // console.log('logoToken: '+ logoToken)
   

const selectstyle = {
   menu: ({ width, ...css }) => ({
      ...css,

      width: "max-content",
      minWidth: "100%",
      fontWeight: 'bold',
 }),
 control: base => ({
   ...base,
   border: '2px solid #adadad',
   fontWeight: 'bold',
   // This line disable the blue border
   // boxShadow: "none"
 })
 }

 const selectstyleQtd = {    
   menu: ({ width, ...css }) => ({
      ...css,      
      width: "max-content",
      minWidth: "100%",
      fontWeight: 'bold',
 }),
 control: base => ({
   ...base,
   border: '2px solid #adadad',
   fontWeight: 'bold',
   // This line disable the blue border
    boxShadow: "none"
 }),
 }


 const renderer = ({ days, hours, minutes, seconds, completed }) => {
   // Render a countdown
   return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
};

 const rendererwhite = ({ days, hours, minutes, seconds, completed }) => {
   // Render a countdown
   return <span style={{ fontSize: '18px', color: '#00414a' }} ><strong>WhiteList Open Sale : {days}d {hours}h {minutes}m {seconds}s</strong></span>;
};


 const BuyButtons = props => { 
   // if(){
   //    return null;
   // }else{

         if (loadButton) {
            return <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>;
         }else{
            if(isConnect){
                  if(saleIsActive){
                     if(!iswhitelist){
                        return <Card style={{ marginBottom: '30px' }}><strong>❌ Your address not is whitelisted.<br />Wait for date to public sale:</strong><br /><Text>🐎 July 1st</Text>
<br /><strong>💳  Or Buy with your Credit Card!</strong>              
                        </Card>;
                     }else{
                        return <Button onClick={buyZefi}><strong>{buttonMint}</strong></Button>;
                     }
                  }else{
                     return <ButtonNada style={{ fontSize: '16px' }} ><Countdown 
                        renderer={renderer}
                        date={new Date(mintStart)} 
                     /></ButtonNada>;
                  }
            }else{
               return <ButtonNada>Connect your Wallet...</ButtonNada>;
            }
         }

   // }
 };

 // console.log('==================================')
 // console.log(valueWithdraw)
 // console.log(valueWithdraw.index)
 // console.log(optionsStaked)
 // console.log(optionsStaked[valueWithdraw.index])
 // console.log('==================================')

 const StakeButtons = props => { 
   return <ContainerButton3 style={{ maxWidth: '250px' }}>               
   <DivCard style={{ paddingLeft: '20px' , paddingRight: '20px' }} >
      <Text style={{ fontSize: '16px', marginBottom: '10px' }}>
      ➡️ Every month a cashback token is generated in the NFTs owners wallet (BSC network) on the snapshot date.<br />
      💎 With this token you access ZCore Vaults and staking for passive income.
      </Text>
   </DivCard>    

   <DivCard style={{ marginTop: '20px' , padding: '10px'}} >
                        <Text style={{ fontSize: '16px' }}>
                        🔄 Connect your wallet in BSC network to view your cashback tokens.
                        {/*                           
                          Next Snapshot:<br /><strong style={{ fontSize: '18px' }}><Countdown 
               renderer={renderer}
               date={new Date(nextSnapshot)} 
            /></strong>
            */}
                        </Text>                 
                     </DivCard>  


   </ContainerButton3>   
 }

 const StakeButtonsOld = props => { 

   let buttonClaim = <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} onClick={clickClaim}><strong style={{ fontSize: '16px' }}>CLAIM</strong></Button>
   if(loadButtonClaim){     
      buttonClaim = <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>
   }


   let buttonWithdraw = <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} onClick={clickUnstake}><strong style={{ fontSize: '16px' }}>WITHDRAW</strong></Button>
   if(loadButtonWithdraw){     
      buttonWithdraw = <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>
   }


   let buttonDeposit = <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} onClick={clickDeposit}><strong style={{ fontSize: '16px' }}>DEPOSIT</strong></Button>
   
   if(optionsStaked.length > 0){
      buttonDeposit = <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} onClick={clickDepositAlert}><strong style={{ fontSize: '16px' }}>DEPOSIT</strong></Button>
   }
   
   if(!stakeapproved){
      buttonClaim = <></>
      buttonWithdraw = <></>
      buttonDeposit = <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} onClick={clickApprove}><strong style={{ fontSize: '16px' }}>APPROVE CONTRACT</strong></Button>
   }
   if(loadButtonDeposit){     
      buttonDeposit = <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>
   }   

         if (loadButton) {
            return <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>;
         }else{
            if(isConnect){
               return <ContainerButton3>               
                     <DivCard style={{ paddingLeft: '20px' , paddingRight: '20px' }} >
                        <Text style={{ fontSize: '16px', marginBottom: '10px' }}>
                          Your Stake: {amountStaked} NFT<br />
                          Total Staked: {stakingOwner.length} NFT
                        </Text>
                          <Select
                             styles={customStyles}
                             isSearchable={false}
                             onChange={handlerStaked}
                             defaultValue={optionsStaked[valueWithdraw.index]}
                             options={optionsStaked}
                           />
                              {buttonWithdraw}           
                          {/* <strong style={{ fontSize: '18px' }}>{amountStaked} / {totalNFT}</strong> */}
                        
                     </DivCard>    
               
               
                     <DivCard style={{ marginTop: '30px' , padding: '20px'}} >
                        <Text style={{ fontSize: '18px' }}>
                          Rewards : <strong style={{ fontSize: '18px' }}>{availableRewards} ETH</strong>
                        </Text>
                           {buttonClaim}                        
                     </DivCard>    
               
               
                     <DivCard style={{ marginTop: '30px' , paddingLeft: '20px' , paddingRight: '20px' }} >
                        <Text style={{ fontSize: '18px', marginBottom: '10px' }}>
                          My Wallet ({optionsNoStaked.length})
                        </Text>
                          <Select
                             styles={customStyles}
                             isSearchable={false}
                             onChange={handlerNoStaked}
                             defaultValue={optionsNoStaked[valueDeposit.index]}
                             options={optionsNoStaked}
                           />
                        {buttonDeposit}
                          {/* <strong style={{ fontSize: '18px' }}>{amountStaked} / {totalNFT}</strong> */}
                        
                     </DivCard>    
               
               </ContainerButton3>    
            }else{
               return <ButtonNada>Connect your Wallet...</ButtonNada>;
            }
         }
 }; 
 

 const msgCon = () => { 
   let msg = 'Connect your wallet...'; 
   if(isConnect){
      if (currentChainId == 56) {
         msg = 'Click on BSC icon to change network.';
      }else{
         msg = 'Click on ETH icon to change network.';
      }
   }
   return msg;
 }

 const WhiteListButtons = props => {       
         if (loadButton) {
            return <><LoadingDiv><SpinnerCircularSplit/></LoadingDiv></>;
         }else{
            if(isConnect){
                     if(iswhitelist){
                        return <Card style={{ marginBottom: '30px' }}><strong>✅ Your address is whitelisted.<br />Wait for the date to mint:</strong><br /><Text style={{ fontSize: '13px' }} >🐎 {currentAccount}</Text></Card>;
                     }else{
                        return <Button 
                        onClick={enterWhiteList}><strong>Enter WhiteList</strong></Button>;
                     }
            }else{
               return <></>;
            }
         }
 };

 

 const AlertaBsc = props => { 
   if (currentChainId == 56) {
   return <ContainerMainMin>
            <Text style={{ fontSize: '14px' , textAlign: 'center', maxWidth: '300px' }} >      
            ⚠️ NFTs that are bought with tokens from the BSC network, but you will receive the NFTs minted on the Ethereum blockchain.<br />➡️ {msgCon()}
            </Text>         
         </ContainerMainMin>
   }else{
      return <ContainerMainMin>
      <Text style={{ textAlign: 'center', maxWidth: '300px' }} >      
      ⚠️ NFTs are minted on the ETH network.<br />➡️ {msgCon()}
      </Text>         
   </ContainerMainMin>
   }
 }






 const ShowSelectQtd = props => { 
    if(!mintEnabled){
      return <></>;
    }else{
      return <Select
         styles={selectstyleQtd}
         isSearchable={false}
         onChange={handler}
         defaultValue={options[valueState]}
         options={options}
   />
    }
 }


 
 
 useEffect(() => {   
    funcGetPriceZefi();
    // getMyNFTs();
    // getOwners();
  }, [currentWeb3, tokenUSD, isConnect, myNFT]);
  

  useEffect(() => {   
   getOwners();
 }, []);

const [statMenu, setStatMenu] = useState(1);

const clickDivMint = (event) => {
   setStatMenu(1);
}


const clickDivStaking = (event) => {
   // getMyNFTs();
   setStatMenu(2);
}


const clickDivDao = (event) => {
   window.open("https://dao.zcore.network/", "_blank");
}



 const ComponentTab = props => { 
   return <div style={{ marginTop: '20px' }}>
      <Tabs>
      <TabList>
       <Tab><b>
         <BrowserView>Buy with Credit Card</BrowserView>
         <MobileView>Credit Card</MobileView>
         </b></Tab>
       {/* <Tab><b>PIX</b></Tab>       */}
       <Tab><b>ETH</b></Tab>       
       <Tab><b>
         <BrowserView>BSC Tokens</BrowserView>
         <MobileView>BSC</MobileView>
         </b></Tab>
       </TabList>


     <TabPanel>


     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            💳 You can use your credit card to purchase NFTs. Select the NFTs quantity and click the button : 

            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Select
              styles={customStyles}
              isSearchable={false}
              onChange={handlerCredit}
              defaultValue={optionsCredit[crossmintQtd]}
              options={optionsCredit}
            />
</div>
              
<div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>               
               <CrossMint/>
</div>
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>

{/* 

     <TabPanel>

     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            💰 To minting NFTs using Pix (Brazil) download the ZCore Finance app : 

            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <a target='_blank' rel="noreferrer" href="https://apps.apple.com/br/app/zcore-finance/id1570794397"><img style={{ width: '163px', height: '50px', margin: '5px' }} src={appstore} /></a>
            <a target='_blank' rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.zcorefinance"><img style={{ width: '163px', height: '50px', margin: '5px' }} src={googleplay} /></a>
</div>
              
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>
 */}


     <TabPanel>


     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>
            🔥 Connect your wallet to Ethereum Mainnet, select the NFTs quantity and click the MINT button.<br />
            Minting through the ETH network is automatic.
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>


     <TabPanel>
     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            🪙 You can use tokens from the BSC network to mint your NFT:
            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <b>Connect your wallet to the BSC Network!</b>
      </div>      
   </DivCard4>     
      </ContainerButton4>       
      </div>      
     </TabPanel>     
   </Tabs>
   </div>
 };


 


 const ImagemCavalo = () => {
   return (
      <div>
        <div>
             <Image width={300} height={300} src={preview}/>
        </div>
      </div>
    );
 };

 const CardCavalo = props => { 
   if(statMenu == 1){
      return  <Card>

      <BoxTitle>
                <h2><a style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={opensea}>About Farm Horses <FaExternalLinkAlt/></a></h2>
       </BoxTitle>
   
   
         <ImageContainer>         
            <ImagemCavalo/>
         </ImageContainer>            
   
   
    <TextPrice style={{ color: '#adadad' }}>
   {/*
   <strong>
   Mint Price
   </strong>
    */}
   </TextPrice>
   <TextPrice>
      <strong>
           1 NFT = {(new BigNumber(zefiPrice + "e-" + decimals)).toFixed(4)} ETH
   <TextGray>(${priceUnique.toFixed(2)})</TextGray>
   </strong>
   </TextPrice>           
   
   
      </Card>
   
    }else{
      return   <Card>

      <BoxTitle>
                <h2><a style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={opensea}>Farm Horses <FaExternalLinkAlt/></a></h2>
       </BoxTitle>
   
   
         <ImageContainer>         
            <ImagemCavalo/>
         </ImageContainer>            
   
   {/*
    <TextPrice style={{ fontSize: '18px' }}>
    ➡️  You can only stake 1 NFT per wallet
   </TextPrice>
    */}
   
   
      </Card>
   }
 }







 




return (
<>
   <ContainerTop/>

<div>
<ContainerMainMinus>
<ContainerButton5>
      <DivCard5 onClick={clickDivMint}>
      <FaPlusCircle style={{ marginRight: '5px' }}/> Mint
      </DivCard5>     
      <DivCard5 onClick={clickDivStaking}>
      <FaHandHoldingUsd style={{ marginRight: '5px' }}/> Staking
      </DivCard5>     
      <DivCard5 onClick={clickDivDao}>
      <FaUniversity style={{ marginRight: '5px' }}/> DAO
      </DivCard5>           
</ContainerButton5>
</ContainerMainMinus>


<ContainerMainMinus>


<CardCavalo/>

     <label style={{ marginTop: '0px' }}>           





{/* <ColoredLine color="#00414a" /> */}

{mintEnabled &&
<ColLat style={statMenu == 1 ? {} : { display: 'none' }}>   
<ShowSelectQtd/>

<BoxTitle>
{isConnect  ?
<Text style={{ maxWidth: '350px' }}>   
   <strong>
      Total : {swapTotal} ETH
   </strong>
</Text>
:
<Text style={{ maxWidth: '350px' }}>   
   <strong>
      Total : 0 ETH
  </strong>
</Text>
}
<TextGray style={{ fontWeight: 'bold' }}>(${(priceUnique * valueState).toFixed(2)})</TextGray>
</BoxTitle>   

<BuyButtons/>    
{/*
<AlertaBsc/>         
*/}

<ContainerButton3>
      <DivCard>
         Minted : <strong>{supplyNft} / {maxHorses}</strong>
      </DivCard>     
</ContainerButton3>    



<ContainerButton3>
      <DivCard>
         <a target="_blank" rel="noreferrer" href="https://opensea.io/account" style={{ color: '#000000' , textDecoration: 'none' }}><FaExternalLinkAlt/> My NFTs : <strong>{myNFT}</strong></a>
      </DivCard>     
</ContainerButton3>          


</ColLat>
} 




{mintEnabled &&
<ColLat style={statMenu == 2 ? {} : { display: 'none' }}>   
<BoxTitle>
   <StakeButtons/>
</BoxTitle> 
</ColLat>
} 


{!mintEnabled &&
<ColLat>
<WhiteListButtons/>
<DivWhiteList>
<Text>
Whitelist Sale Price: <strong>{(new BigNumber(zefiPrice + "e-" + decimals)).toFixed(6)} ETH</strong><br />
Public Sale Price: <strong>{(new BigNumber(zefiPrice + "e-" + decimals)*2).toFixed(6)} ETH</strong>
</Text>
</DivWhiteList>
<ColoredLine color="#00414a"/>
<Text>
{msgCon()}
</Text>
<ColoredLine color="#00414a"/>
<Countdown 
renderer={rendererwhite}
date={new Date(whiteStop)} 
   />

</ColLat>
}
{/*  */}
<ToastContainer 
position="top-right"
autoClose={20000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>


</label>



</ContainerMainMinus>      

<ContainerMainSelect>
   <ComponentTab/>
</ContainerMainSelect>      


<ContainerMainSelect>
   <Getownersrender/>
</ContainerMainSelect>

</div>

</>
);
}