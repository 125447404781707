import axios from "axios";

const tokens = axios.create({
  baseURL: "https://raw.githubusercontent.com/zcore-coin/zefi-frontend-amm/main",
});


const tokensCake = axios.create({
  baseURL: "https://raw.githubusercontent.com/pancakeswap/token-list/main/lists",
});


const myTokens = axios.create({
  baseURL: "https://api.bscscan.com/api",
});

export { tokens , tokensCake , myTokens } ;