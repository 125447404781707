const zefiAddress = '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07';  // ZEFI
const zboAddress = '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8';  // ZBO
const infinityAmmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const whiteListEth = '0x5079b45e948A76BE17f87F14838D4048f727cFe4'; // MAINNET
// const whiteListEth = '0x9e58aD94Ad9644E83f7904AC0fA583C574Cf854E'; //TESTNET
const whiteListBsc = '0x4049e37DdE0d61aDB6DB94b18966b9319e65E487';

const buyEthAddress = '0x9c9004dE368B1968C953A5022271DE80310489e4'; // MAINNET
// const buyEthAddress = '0x3d40d54b953fa652ffb8d3bd876bad355312becb'; //TESTNET

const buyBscAddress = '0x0017fa097992e975fCbd3BB8091dEd599F269cDc'; // MAINNET
const buyBscAddressOld = '0x71bd3B21EaF096aBD3FE50bb764ad2A32024CDE2'; // MAINNET
// const buyBscAddress = '0xd7F85753d4f50B765247c805375a69a19EC6323c'; // TESTNET


// const stakingNft = '0x71bd3B21EaF096aBD3FE50bb764ad2A32024CDE2'; // MAINNET
const stakingNft = '0xed940079Ac9a42D7a35271484C3F84a77369f7B3'; // TESTNET

const reciboBsc = "0xfaE433Bd83dbC3654E1cbb3e6F187F2cDDfb3b8b";
const nomeRecibo = "ZCRFMv2";

const opensea = 'https://club.zcore.network/';
const whiteStop = 1656460800000;
const mintStart = 1656460800000;

const nextSnapshot = 1658239200000;

export { zefiAddress , zboAddress, buyBscAddress, buyEthAddress, buyBscAddressOld, infinityAmmount, opensea, mintStart, whiteListEth, whiteListBsc, whiteStop, stakingNft, reciboBsc, nomeRecibo, nextSnapshot }