
import styled from "styled-components";


export const Container = styled.div`
   margin-top: 30px;
  

   strong {
      margin-top: 100px;
      margin-left: 26px;
      color: var(--light-gray);

      font-size: 15px;
      font-weight: 400;
   }

   p {
      margin-top: 10px;
      font-size: 13px;
      color: var(--light-gray);
   }

   &::before {
      content: '';
      display: block;
      width: 280px;
      height: 1px;
      background: var(--light-gray);
      margin: 0 auto;
   }

   svg {
      position: relative;
      /* bottom: 0; */
      left: 270px;
      /* right: ; */
      /* display: block; */
      width: 35px;
      height: 35px;
      cursor: pointer;
   }
`
export const ContentValue = styled.div`
   margin-top: 20px;
`

export const ContentText = styled.div`
   margin-top: 10px;
   margin-bottom: 25px;
   text-align: center;
   p {
      font-size: 13px;
   }
`
export const Input = styled.input`
   margin-top: 8px;
   width: 250px;
   padding: 10px 20px;
   background-color: transparent;

   border:  1px solid;
   border-radius: 10px ;
   border-color: var(--light-gray);

   font-size: 18px;

   margin-left: 26px;

`
export const SliderContent = styled.div`
   margin: 20px;
`

export const ContainerButton = styled.div`
   display: flex;
`;

export const Button = styled.button`
   width: 150px;
   padding: 8px;
   margin-top: 25px;
   margin-left: 4px;

   background-color: transparent;
   border:  1px solid;
   border-radius: 20px;

   cursor: pointer;

   /* @media (max-width: 375px) {
      
   } */
`

export const Button2 = styled.button`
   width: 150px;
   padding: 8px;
   margin-top: 25px;
   margin-left: 4px;

   background-color: #5f968b;
   color: #fff;
   border:  1px solid;
   border-radius: 20px;

   cursor: pointer;
`;
