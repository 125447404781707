import React, { useState, useEffect, useMemo, useRef } from "react";
import Select, { components } from "react-select";
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from "styled-components";
import { buyBscABI , buyBscABIold } from "../../contract/abi/buyBscABI";
import whiteListABI from "../../contract/abi/whiteListABI";
import tokenABI from "../../contract/abi/tokenABI";
import ReciboABI from "../../contract/abi/ReciboABI";
import { SpinnerCircularSplit } from 'spinners-react';
import { ToastContainer, toast } from 'react-toastify';
import BigNumber from "bignumber.js";
import { tokens , tokensCake } from "../../helpers/tokens";
import { Container, Row, Col } from 'react-grid-system';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { zefiAddress , zboAddress, buyBscAddress, buyBscAddressOld, buyEthAddress, infinityAmmount, opensea, mintStart, whiteListEth, whiteListBsc, whiteStop, nomeRecibo, reciboBsc, nextSnapshot } from "../../contract/contracts";
import { FaCopy, FaExternalLinkAlt, FaHandHoldingUsd, FaPlusCircle, FaVoteYea, FaUniversity } from "react-icons/fa";
import Countdown from 'react-countdown';
import useOnclickOutside from "react-cool-onclickoutside";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import axios from 'axios';



import 'react-toastify/dist/ReactToastify.css';

import {
   DivWhiteList,
   ContainerMain,
   ContainerTop,
   Card,
   ImageBox,
   Image,
   ImageContainer,   
   Button,
   Button2,
   Button3,
   Button4,
   DivCard,
   BoxTitleContainer,
   BoxTitle,
   BalanceConteiner,
   BalanceContent,
   StatisticsContainer,
   StatisticsContent,
   imgright,
   Text,
   TextPrice,
   ContainerButton,
   ContainerButton2,
   ContainerMainMin,
   ContainerButton3,
   Button5,
   Button6,
   ImageBoxMax,
   TextBalance,
   ContainerPrinc,
   ContainerTopMin,
   TextGray,
   ColButton,
   ColLat,
   ContainerMainSelect,
   CardSelect,
   ButtonNada,
   ImageBlank,
   ImageLarge
} from "./style";

import logoZefi from '../../assets/zefi.png';
import logoPix from '../../assets/pix.png';
import logoEth from '../../assets/eth.png';
import logoZbo from '../../assets/zbo.svg';

import cards from '../../assets/cards.png';
import horse from '../../assets/horse.png';
import p1 from '../../assets/p1.png';
import p2 from '../../assets/p2.png';
import p3 from '../../assets/p3.png';
import p4 from '../../assets/p4.png';
import p5 from '../../assets/p5.png';
import p6 from '../../assets/p6.png';
import p7 from '../../assets/p7.png';
import p8 from '../../assets/p8.png';
import p9 from '../../assets/p9.png';
import preview from '../../assets/promom.gif';

import zcrfm from '../../assets/zcrfm.png';

import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';

// import preview from '../../assets/reveral.svg';
import unknowntoken from '../../assets/unknown-token.png';
import { DetailsList } from "../DetailsList";
import { ConsoleView } from "react-device-detect";
import coingecko from "../../helpers/coingecko";

/*
const min = 1;
const max = 9;
const rand =  Math.floor(Math.random() * max) + min ;
// console.log('rand: '+rand)
let preview;
if(rand == 1){
   preview = p1;
}else if(rand == 2){
   preview = p2;
}else if(rand == 3){
   preview = p3;
}else if(rand == 4){
   preview = p4;
}else if(rand == 5){
   preview = p5;
}else if(rand == 6){
   preview = p6;  
}else if(rand == 7){
   preview = p7;               
}else if(rand == 8){
   preview = p8;               
}else{
   preview = p9;
}
*/


// const zefiAddress = '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07';  // ZEFI
// const zboAddress = '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8';  // ZBO
// const buyBscAddress = '0x2CA5fDc1Cd421beAf1d83f199f3F5d407CF82F9e';
// const infinityAmmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const CardRed = styled.div`
width: 100%;  
max-width: 480px;
  /* margin: 4px 4px auto; */
  padding: 10px;
  background-color: #00588b;
  color: #FFFFFF;



  border-radius: 10px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
   justify-content: center;
   align-items: center;
   text-align: center;

`

export const LoadingDiv = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

const ColoredLine = ({ color }) => (
   <hr
       style={{
          marginTop: '10px',
          marginBottom: '10px',
           color: color,
           backgroundColor: color,
           height: 3
       }}
   />
);


export const ContainerButton4 = styled.div`

  margin: 0px;

  @media (max-width: 900px) {
   /* 
   display: flex;
    flex-direction: row;
    */
  }
`;


export const DivCard4 = styled.div`
   max-width: 480px;
   padding: 8px;
   margin: 0px;

   justify-content: center;
   align-items: center;
   text-align: center;

   background-color: #f3fbf0;
   box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
   color: #000000;
   border:  0px solid;
   border-radius: 10px;

   @media (max-width: 900px) {
    width: 100%;
    padding: 8px;
    margin: 0px;
   }

`;

export const BuyBox = styled.div`  
  margin-top: 20px;
`;


export const ContainerMainMinus = styled.div`
/* max-width: 1700px; */
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0;
    gap: 0rem;
  }
`;


export const ContainerButton5 = styled.div`
width: 100%;
max-width: 640px;
margin: 15px 0px 15px 0px;

display: flex;

justify-content: center;
align-items: center;
text-align: center;

@media (max-width: 900px) {
   display: inline-flex;
   margin: 10px 0px 10px 0px;
  /*
  max-width: 320px;
   display: flex;
  flex-direction: row;
  */
}
`;

export const DivCard5 = styled.div`
 width: 50%;
 padding: 8px;
 margin: 5px;

 justify-content: center;
 align-items: center;
 text-align: center;

 background-color: #00414a;
 box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
 color: #FFFFFF;
 border:  0px solid;
 border-radius: 10px;
 font-weight: bold;
 text-transform: uppercase;

 cursor: pointer;

 @media (max-width: 900px) {
   width: 240px;
   margin: 10px;
   padding: 8px;
   /*
   width: 50%;
  padding: 8px;
  margin: 0px;
  */
 }

`;

export function Main ({ currentWeb3, currentAccount, currentChainId, isZefi, newToken, isConnect, tokenTicker, tokenUSD, chainBSC, chainETH }) {   

   // FORCE
   const [mintEnabled, setMintEnabled] = useState(true)   
   const [saleIsActive, setSaleIsActive] = useState(true)   
   const [iswhitelist, setIswhitelist] = useState(true)  
   const [nftOwners, setNftOwners] = useState(-1)  
   const [nftList, setNftList] = useState([])
   const [totalScore, setTotalScore] = useState([])
   const [customLink, setCustomLink] = useState('https://join.zcore.network/')

   const [reciboPrice, setReciboPrice] = useState(0)

   let isZBO = false;
   if(newToken.toLowerCase() == '0x7d3550d0b0ac3590834ca6103907cd6dd41318f8'){
      isZBO = true;
   }


   if(newToken.toLowerCase() == '0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07'){
      isZefi = true;
   }
   
   // console.log('mintEnabled: ' + mintEnabled);

if(isZefi){
   tokenTicker = 'ZEFI';
}else{
   if(tokenTicker == undefined){
      tokenTicker = '';
   }
}

const [ticker, setTicker] = useState(tokenTicker)

// console.log('newToken: ' + newToken);   
// console.log('isZefi: ' + isZefi);   
// // console.log('newToken: ' + datacoingecko);
// // console.log('tokenArrayMetadata isInitializing : ' + isInitializing);
// // console.log('tokenArrayMetadata isInitialized : ' + isInitialized);

const getTicker = async () => {
   if(currentWeb3 && !isZefi){
      const tokenContract = new currentWeb3.eth.Contract(tokenABI, newToken);
      await tokenContract.methods.symbol().call().then(function (response) {
      // console.log('ticker: ' + response);
        setTicker(response.toUpperCase())
      });
      await tokenContract.methods.decimals().call().then(function (response) {
         // console.log('decimals: ' + response);
         setDecimals(response)
      });          
   }
}


useEffect(() => {
   // POST request using axios inside useEffect React hook
   let urlRef = `https://join.zcore.network/${newToken}/${tokenTicker}/${currentAccount}?utm_source=Invitation&utm_medium=${currentAccount}&utm_campaign=${tokenTicker}`;
   if(isZefi){
      urlRef = `https://join.zcore.network/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07/ZEFI/${currentAccount}?utm_source=Invitation&utm_medium=${currentAccount}&utm_campaign=${tokenTicker}`;
   }
   // console.log(urlRef)
   const data = { 
      "domain": "bit.ly",
      "tags": [
         "nft_referral"
       ],      
      "long_url": urlRef
   };
   axios.defaults.headers.common['Authorization'] = '00bfce267a867673a7af183ea725a2fc76e479ef';
   axios.post('https://api-ssl.bitly.com/v4/shorten', data)
       .then(response => setCustomLink(response.data.link));

// empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [newToken, tokenTicker, currentAccount]);

const [logoToken, setLogoToken] = useState(unknowntoken)
// let logoToken = 'https://app.1inch.io/assets/token-logo/unknown-token.png';

{/*
// useEffect(() => {
   if(isZefi){
      setLogoToken('https://zcore.network/coins/ZEFI.png');
   }else{
      getLogo();
   }
// }, [isZefi]); // Apenas re-execute o efeito quando o count mudar
*/}

const getLogo = async () => {

   if(isZefi){
      setLogoToken('https://zcore.network/coins/ZEFI.png');
   }else{

   tokens
      .get("/src/constants/token/pancakeswap.json?version=2")
      .then(function (response) {
         // console.log(response);
         // console.log('logoToken: ' + response.data.tokens.length);
         const dataTokens = response.data.tokens;

         try {
            if(!isZefi){
                  for (let i = 0; i < dataTokens.length; i++) {      
                     // // console.log('pancake: ' + newToken);
                     // // console.log('pancake: ' + dataTokens[i].address);
                     if((newToken.toLowerCase() === dataTokens[i].address.toLowerCase()) && dataTokens[i].logoURI !== undefined){
                        setLogoToken(dataTokens[i].logoURI);
                        // // console.log('logoToken #################################################');
                        // // console.log('logoToken: ' + dataTokens[i].name);
                        // // console.log('logoToken: ' + dataTokens[i].logoURI);
                        // // console.log('logoToken #################################################');   
                     }         
                  }
            }
         } catch (e){            
         }  
       })
      .catch((err) => {
      });



      // console.log('logoToken: start!')
       tokensCake
          .get("/pancakeswap-extended.json")
          .then(function (response) {
            // // console.log('logoToken: ' + response.data.tokens.length);
            const listTokesCake = response.data.tokens;
            try {
               if(!isZefi){
                     for (let i = 0; i < listTokesCake.length; i++) {      
                        if((newToken.toLowerCase() === listTokesCake[i].address.toLowerCase()) && listTokesCake[i].logoURI !== undefined){
                           setLogoToken(listTokesCake[i].logoURI);
                           // // console.log('logoToken #################################################');
                           // // console.log('logoToken: ' + listTokesCake[i].name);
                           // // console.log('logoToken: ' + listTokesCake[i].logoURI);
                           // // console.log('logoToken #################################################');   
                        }         
                     }
               }
            } catch (e){
            }  
          })
          .catch((err) => {            
          });      
   }
}

{/*

  useEffect(() => {

   if(isZefi){
      setLogoToken('https://zcore.network/coins/ZEFI.png')
   }else{

   if(logoToken === 'https://app.1inch.io/assets/token-logo/unknown-token.png'){

   tokens
      .get("/src/constants/token/pancakeswap.json?version=2")
      .then(function (response) {
         // console.log(response);
         // console.log('pancake: ' + response.data.tokens.length);
         const dataTokens = response.data.tokens;

         try {
            if(!isZefi){
                  for (let i = 0; i < dataTokens.length; i++) {      
                     // // console.log('pancake: ' + newToken);
                     // // console.log('pancake: ' + dataTokens[i].address);
                     if((newToken.toLowerCase() === dataTokens[i].address.toLowerCase()) && dataTokens[i].logoURI !== undefined){
                        setLogoToken(dataTokens[i].logoURI);
                        // console.log('#################################################');
                        // console.log('pancake: ' + dataTokens[i].name);
                        // console.log('pancake: ' + dataTokens[i].logoURI);
                        // console.log('#################################################');   
                     }         
                  }
            }
         } catch (e){
            // logoToken  = 'https://app.1inch.io/assets/token-logo/unknown-token.png';
         }  

       })
      .catch((err) => {
         // setDataTokens(null);
        console.error("pancake: ops! ocorreu um erro" + err);
      });
   }   

   if(logoToken === 'https://app.1inch.io/assets/token-logo/unknown-token.png'){

      // console.log('pancakeReload: start!')
       tokensCake
          .get("/pancakeswap-extended.json")
          .then(function (response) {
            // console.log('pancakeReload: ' + response.data.tokens.length);
            const listTokesCake = response.data.tokens;

            try {
               if(!isZefi){
                     for (let i = 0; i < listTokesCake.length; i++) {      
                        if((newToken.toLowerCase() === listTokesCake[i].address.toLowerCase()) && listTokesCake[i].logoURI !== undefined){
                           setLogoToken(listTokesCake[i].logoURI);
                           // console.log('#################################################');
                           // console.log('pancakeReload: ' + listTokesCake[i].name);
                           // console.log('pancakeReload: ' + listTokesCake[i].logoURI);
                           // console.log('#################################################');   
                        }         
                     }
               }
            } catch (e){
               // logoToken  = 'https://app.1inch.io/assets/token-logo/unknown-token.png';
            }  

          })
          .catch((err) => {
            console.error("pancakeReload: ops! ocorreu um erro" + err);
          });
   }


   if(logoToken === 'https://app.1inch.io/assets/token-logo/unknown-token.png'){

          coingecko
          .get("/" + newToken)
          .then(function (response) {
             // console.log(response);
             try {
                if(!isZefi){
                  setLogoToken(response.data.image.large)
                }
             } catch (e){
             }  
           })
          .catch((err) => {
            console.error("coingecko: ops! ocorreu um erro" + err);
          });
   }

   }
      // console.log('logoToken: '+ logoToken);          
    
}, [coingecko, tokens, tokensCake, logoToken, isZefi, newToken]);   

*/}

// // console.log('logoToken: '+ logoToken);


let temp_setTokenUSD;
let temp_setpriceUnique;
let temp_setzefiPrice;
let temp_setApproved;
let temp_setTokenBalanceBalance;
let temp_setnftTotal;

const [dbMy, setDbMy] = useState([])
// const [tokenUSD, setTokenUSD] = useState(0)
const [tokenBalance, setTokenBalanceBalance] = useState(0)
const [reciboBalance, setReciboBalance] = useState(0)
const [decimals, setDecimals] = useState(18)

const [nftTotal, setnftTotal] = useState(0)
const [nftTotalEth, setnftTotalEth] = useState(0)
const [nftTotalOLD, setnftTotalOLD] = useState(0)
const [nftTotalEthOLD, setnftTotalEthOLD] = useState(0)

const [totalReferrals, setTotalReferrals] = useState(0)
const [zefiPrice, setzefiPrice] = useState(0)
const [loadButton, setloadButton] = useState(false)
const [approved, setApproved] = useState(true)



// const showPrice = `Price: ${(new BigNumber(zefiPrice + "e-" + decimals)).toFixed(6)} ${ticker}`

// // console.log('allowanceZefi new: ' + allowanceZefi);
// console.log('approved ------> '+ approved);

const [valueState,setValueState] = useState(0)
// create a function that handle the React-select event and
// save the value of that event on an state every time the component change
    const handler = (event) => {
        const value = event.value
        // setValueState((value * (zefiPrice/1e18)).toFixed(6))

   if(!isZefi){
      // const callSwapPrice = value * priceinZefi;
      // console.log('getSwapPrice: '+ value);
      getSwapZefi(value, newToken, currentWeb3, decimals);
   }else{
      if(zefiPrice > 0 && value > 0){
         const qtdComp = (zefiPrice/1e18) * value;
         // console.log('qtdComp: '+ qtdComp);
         setSwapTotal(qtdComp)
         // setSwapTotal(value * (zefiPrice/1e18)).toFixed(6);
      }
   }
        
        setValueState(value);
        //setValueText(`Total : ${(value * (new BigNumber(zefiPrice + "e-" + decimals))).toFixed(6)} ${ticker}`)
        if(zefiPrice > 0 && decimals > 0){
            // CALCULO SEM SPREAD   
            // setValueText(value * (new BigNumber(zefiPrice + "e-" + decimals)).toFixed(6))
        }
}

const setmytoken = (event) => {
   const value = event.contractAddress;
   // console.log(event);
   if (typeof window !== 'undefined') {
      let newUrl;
      if(window.location.hostname == 'localhost'){
         newUrl = 'http://localhost:3000/' + value + '/' + event.tokenSymbol.toUpperCase();
      }else{
         newUrl = 'https://' + window.location.hostname + '/' + value + '/' + event.tokenSymbol.toUpperCase();
      }
      // console.log(newUrl);
      window.location.href = newUrl;
 }
}


const [swapTotal,setSwapTotal] = useState(0)
const [priceUnique,setpriceUnique] = useState(0)


/*
const funcGetPriceUsd = async () => {
   if(currentWeb3){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      await buyBscContract.methods.getPriceUsd(zefiAddress, '1000000000000000000').call().then(function (response) {
         
         temp_setTokenUSD = response/1e18;
         setTokenUSD(response/1e18);

         // console.log('tokenPriceUSD: ' + tokenUSD);
      });   
   }   
}
*/

const funcGetPriceZefi = async () => {
   try {
   if(currentWeb3 && currentChainId == chainBSC){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      const whiteListBscContract = new currentWeb3.eth.Contract(whiteListABI, whiteListBsc); 

      /*
      await buyBscContract.methods.whitelistMintEnabled().call().then(function (response) {
         // console.log('whitelistMintEnabled: ' + response);
         if(response == true){
            setWhitelistMintEnabled(true);
         }else{
            setWhitelistMintEnabled(false);
         }
         
      });
      */

      /*
      await whiteListBscContract.methods.isUserWhiteListed(currentAccount).call().then(function (response) {
         // console.log('isWhiteList: ' + response);
         if(response == true){
            setIswhitelist(true);
         }else{
            setIswhitelist(false);
         }         
      });
      */      


      await buyBscContract.methods.ZEFI_PRICE().call().then(function (response) {
         if(isZefi){
            // console.log('zefiPrice: ' + zefiPrice);
            // console.log('priceUnique: ' + priceUnique);
            // console.log('priceUnique: ' + tokenUSD);

            setpriceUnique(new BigNumber(response + "e-" + decimals) * tokenUSD)
            setzefiPrice(response);
         }else{
            setpriceUnique(new BigNumber(response + "e-" + 18) * tokenUSD) // Força ZEFI
            // console.log('priceUnique #########################################');
            // console.log('priceUnique: ' + (new BigNumber(response + "e-" + 18) * tokenUSD));
            // console.log('priceUnique: ' + response);
            // console.log('priceUnique: ' + priceUnique);
            // console.log('priceUnique: ' + tokenUSD);
            // console.log('priceUnique: ' + decimals);
            // console.log('priceUnique #########################################');
         }
      });
      funcGetAllowance();
   }
   } catch (e) {
      //console.log(e)
   }
}


const getData = async (key) => {
   let thisData = '0x0000000000000000000000000000000000000000';
   try{
      thisData = await AsyncStorage.getItem(key)
      if(thisData.charAt(0) != 0 || thisData.charAt(1) != 'x' || thisData.length !=  42){
         thisData = '0x0000000000000000000000000000000000000000';
      }      
    } catch (e) {
      thisData = '0x0000000000000000000000000000000000000000';
    }   
 
   return thisData;
 }


const funcGetAllowance = async () => {

   // console.log('funcGetAllowance: start!')
   if(currentWeb3){
      // console.log('funcGetAllowance: run...')
      const zefiContract = new currentWeb3.eth.Contract(tokenABI, zefiAddress); 

      if(isZefi){
         await zefiContract.methods.allowance(currentAccount, buyBscAddress).call().then(function (allowance) {
           // console.log('allowance ZEFI: ' + allowance);
           if(allowance == 0){
              setApproved(false)
              temp_setApproved = false;
           }
         });
      }else{
         const tokenContract = new currentWeb3.eth.Contract(tokenABI, newToken);
         await tokenContract.methods.allowance(currentAccount, buyBscAddress).call().then(function (allowance) {
           // console.log('allowance token: ' + allowance);
           if(allowance == 0){
               setApproved(false)
              temp_setApproved = false;
           }
         });   
      }
   }   
}



const getRecibo = async () => {   
   if(currentWeb3 && decimals > 0){
      const reciboContract = new currentWeb3.eth.Contract(ReciboABI, reciboBsc); 
         await reciboContract.methods.balanceOf(currentAccount).call().then(function (balance) {
            setReciboBalance((new BigNumber(balance + "e-" + decimals)).toFixed(2))
          });
   }
}


const getZefiPrice = async () => {
try {
   // console.log('getZefiPrice...');
   getRecibo();
   funcGetPriceZefi();


   if(currentWeb3 && decimals > 0 && currentChainId == chainBSC){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      const zefiContract = new currentWeb3.eth.Contract(tokenABI, zefiAddress); 

/*
      await buyBscContract.methods.getPriceUsd(zefiAddress, '1000000000000000000').call().then(function (response) {
         
         temp_setTokenUSD = response/1e18;
         setTokenUSD(response/1e18);

         // console.log('tokenPriceUSD: ' + tokenUSD);
      });
*/
      // console.log('priceUnique isZefi: ' + isZefi);

      if(isZefi){
/*
         await buyBscContract.methods.ZEFI_PRICE().call().then(function (response) {
            // console.log('zefiPrice: ' + zefiPrice);
            // console.log('priceUnique: ' + priceUnique);
            // console.log('priceUnique: ' + tokenUSD);

            setpriceUnique(new BigNumber(response + "e-" + decimals) * tokenUSD)
            setzefiPrice(response);
            temp_setpriceUnique = new BigNumber(response + "e-" + decimals) * tokenUSD;
            temp_setzefiPrice = response;

         });
*/

/*
         await zefiContract.methods.allowance(currentAccount, buyBscAddress).call().then(function (allowance) {
           // console.log('allowance ZEFI: ' + allowance);
           if(allowance == 0){
              setApproved(false)
              temp_setApproved = false;
           }
         });
*/

         await zefiContract.methods.balanceOf(currentAccount).call().then(function (balance) {
            setTokenBalanceBalance((new BigNumber(balance + "e-" + decimals)).toFixed(6))
            temp_setTokenBalanceBalance = (new BigNumber(balance + "e-" + decimals)).toFixed(6);
            // console.log('tokenBalance: ' + tokenBalance)
          });


      }else{

/*
         await buyBscContract.methods.ZEFI_PRICE().call().then(function (response) {
            setpriceUnique(new BigNumber(response + "e-" + 18) * tokenUSD) // Força ZEFI
            // temp_setpriceUnique = new BigNumber(response + "e-" + decimals) * tokenUSD;
            // console.log('priceUnique #########################################');
            // console.log('priceUnique: ' + (new BigNumber(response + "e-" + 18) * tokenUSD));
            // console.log('priceUnique: ' + response);
            // console.log('priceUnique: ' + priceUnique);
            // console.log('priceUnique: ' + tokenUSD);
            // console.log('priceUnique: ' + decimals);
            // console.log('priceUnique #########################################');
         });
*/
         await buyBscContract.methods.getPriceWithToken(newToken, 1).call().then(function (response) {
            setzefiPrice(response);
            temp_setzefiPrice = response;
            // console.log('tokenPrice: ' + zefiPrice);
         });
/*
         const tokenContract = new currentWeb3.eth.Contract(tokenABI, newToken);
         await tokenContract.methods.allowance(currentAccount, buyBscAddress).call().then(function (allowance) {
           // console.log('allowance token: ' + allowance);
           if(allowance == 0){
               setApproved(false)
              temp_setApproved = false;
           }
         });         
*/
         const tokenContract = new currentWeb3.eth.Contract(tokenABI, newToken);
         await tokenContract.methods.balanceOf(currentAccount).call().then(function (balance) {
            setTokenBalanceBalance((new BigNumber(balance + "e-" + decimals)).toFixed(6))
            temp_setTokenBalanceBalance = (new BigNumber(balance + "e-" + decimals)).toFixed(6);
            // console.log('tokenBalance: ' + tokenBalance)
          });
         
      }

      getTotalComprado();

/*
      setTokenUSD(temp_setTokenUSD);
      setpriceUnique(temp_setpriceUnique)
      setzefiPrice(temp_setzefiPrice);
      setApproved(temp_setApproved)
      setTokenBalanceBalance(temp_setTokenBalanceBalance)
      setnftTotal(temp_setnftTotal);
*/      
   }
} catch (e) {
}
}


const getTotalComprado = async () => {
   const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
   const buyBscContractOld = new currentWeb3.eth.Contract(buyBscABIold, buyBscAddressOld); 

   await buyBscContractOld.methods.users(currentAccount).call().then(function (response) {
      setnftTotalOLD(response);
      // console.log('total comprado: ' + response);   
   });
   await buyBscContractOld.methods.usersMinted(currentAccount).call().then(function (response) {
      setnftTotalEthOLD(response);
      // console.log('total comprado: ' + response);   
   });
   
   
   await buyBscContract.methods.users(currentAccount).call().then(function (response) {
      setnftTotal(response);
      // console.log('total comprado: ' + response);   
   });
   await buyBscContract.methods.usersMinted(currentAccount).call().then(function (response) {
      setnftTotalEth(response);
      // console.log('total comprado: ' + response);   
   });   


   await buyBscContract.methods.referrals(currentAccount).call().then(function (response) {
      setTotalReferrals(response);
      // console.log('total comprado: ' + response);   
   });      
}


const getSwapZefi = async (valor, newToken, currentWeb3, decimals) => {
   if(currentWeb3 && newToken && valor > 0 && decimals > 0){
      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
      // console.log('getSwapPrice: ' + newToken);
      // console.log('getSwapPrice: ' + valor);
      await buyBscContract.methods.getPriceWithToken(newToken, valor).call().then(function (response) {
         // console.log('getSwapPrice: ' + response);
         setSwapTotal((new BigNumber(response + "e-" + decimals)).toNumber().toFixed(6));
      });   
   }
}




   // getLogo();
   // getTicker();
   // getZefiPrice();
   


const aproveContract = async () => {
   if(currentWeb3){
      setloadButton(true);


      if(isZefi){


      const zefiContract = new currentWeb3.eth.Contract(tokenABI, zefiAddress); 
      await zefiContract.methods.approve(buyBscAddress, infinityAmmount).send({from:currentAccount}).then((tx)=>{
         //action prior to transaction being mined
         // console.log('comprou -----------------------> ' + tx)
         setloadButton(false);
         setApproved(true);
         toast.success(`🐎 Contract approved! Now you can mint NFT...`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         //action to perform when user clicks "reject"
         setloadButton(false);
         setApproved(false);
      })     
      
      

      }else{

         const tokenContract = new currentWeb3.eth.Contract(tokenABI, newToken); 
         await tokenContract.methods.approve(buyBscAddress, infinityAmmount).send({from:currentAccount}).then((tx)=>{
            //action prior to transaction being mined
            // console.log('comprou -----------------------> ' + tx)
            setloadButton(false);
            setApproved(true);
            toast.success(`🐎 Contract approved! Now you can mint NFT...`)
         })
         .catch(()=>{
            // console.log('rejeitou...')
            //action to perform when user clicks "reject"
            setloadButton(false);
            setApproved(false);
         })              

      }

   }
}


const buyWithZefi = async (amount) => {
   if(currentWeb3){

      let referral = await getData('@referral');
      console.log('@referral: '+ referral)
      if(referral ==  currentAccount){
         referral = '0x0000000000000000000000000000000000000000'
      }      

      const enableSwap = (new BigNumber(tokenBalance).toNumber()  > new BigNumber(swapTotal).toNumber()) ? true : false;
      
      // console.log('buyWithZefi: ' + isZefi);
      // console.log('buyWithZefi: ' + newToken);
      // console.log('buyWithZefi: ' + amount);    
      // console.log('buyWithZefi: ' + new BigNumber(tokenBalance).toNumber());
      // console.log('buyWithZefi: ' + new BigNumber(swapTotal).toNumber());
      // console.log('buyWithZefi: ' + enableSwap);


      if(enableSwap){
         // console.log('buyWithZefi: GO!');

         setloadButton(true);     

      if(isZefi){


      const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
/*
      await buyBscContract.methods.buy(amount, 1).send({from:currentAccount}).then(function (response) {
         // console.log('buy: ' + response);
         getTotalComprado();
         toast(`🐎 ${amount} NFT comprado! Aguarde a entrega no seu endereço ${currentAccount} na rede ETH.`)
       });;
*/



      await buyBscContract.methods.buy(amount, referral).send({from:currentAccount}).then((tx)=>{
         //action prior to transaction being mined
         // console.log('comprou -----------------------> ' + tx)
         setloadButton(false);
         getZefiPrice();
         toast.success(`🐎 ${amount} NFT minted! Wait a few minutes for the bridge to send from the BSC network to ETH. The NFT will be sent to the same wallet address on the Ethereum blockchain.`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         //action to perform when user clicks "reject"
         setloadButton(false);
      })       



      }else{


         if(isZBO){

            const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
                  await buyBscContract.methods.buyWithZbo(amount, referral).send({from:currentAccount}).then((tx)=>{
                     //action prior to transaction being mined
                     // console.log('comprou -----------------------> ' + tx)
                     setloadButton(false);
                     getZefiPrice();
                     toast.success(`🐎 ${amount} NFT minted! Wait a few minutes for the bridge to send from the BSC network to ETH. The NFT will be sent to the same wallet address on the Ethereum blockchain.`)
                  })
                  .catch(()=>{
                     // console.log('rejeitou...')
                     //action to perform when user clicks "reject"
                     setloadButton(false);
                  })                 

         }else{
            const buyBscContract = new currentWeb3.eth.Contract(buyBscABI, buyBscAddress); 
            await buyBscContract.methods.buyWithToken(newToken, amount, referral).send({from:currentAccount}).then((tx)=>{
               //action prior to transaction being mined
               // console.log('comprou -----------------------> ' + tx)         
               setloadButton(false);
               getZefiPrice();
               toast.success(`🐎 ${amount} NFT minted! Wait a few minutes for the bridge to send from the BSC network to ETH. The NFT will be sent to the same wallet address on the Ethereum blockchain.`)
            })
            .catch(()=>{
               // console.log('rejeitou...')
               //action to perform when user clicks "reject"
               setloadButton(false);
            })             
         }

      }
   }else{
      toast.error(`🐎 Insufficient balance...`)
   }
   }
}


const enterWhiteList = async () => {
   if(currentWeb3){      

      // console.log('addAddressToWhitelist: GO!');
      // console.log('addAddressToWhitelist: '+ currentAccount);
      setloadButton(true);     

      const whiteListBscContract = new currentWeb3.eth.Contract(whiteListABI, whiteListBsc); 
      await whiteListBscContract.methods.addAddressToWhitelist().send(
         {
            from:currentAccount,
         }
         ).then((tx)=>{
         //action prior to transaction being mined
         // console.log('entrou na whitelist -----------------------> ' + tx)
         setloadButton(false);
         setIswhitelist(true);
         // toast.success(`Your ${currentAccount} address is whitelisted. Wait for the date to mint.`)
         toast.success(`🐎 You joined the Whitelist! Wait date for mint.`)
      })
      .catch(()=>{
         // console.log('rejeitou...')
         //action to perform when user clicks "reject"
         setloadButton(false);
      })
   }
}

// const stakeRecibo = async () => {
//    window.open("https://vaults.zcore.farm/stake");
// }


const stakeRecibo = () => {
   window.open("https://vaults.zcore.farm/stake");
   // this.setState(prevState => ({
   //   isToggleOn: !prevState.isToggleOn
   // }));
 }

const aboutScore = () => {
   toast.success(`🐎 Each NFT has a score according to its rarity. If the NFTs have not yet been revealed, they all have the same score.`)
} 

const buyZefi = async () => {
   if(currentWeb3){
      if(approved){
         // console.log(`Mint with ${ticker}: ${valueState}`)   
         if(valueState == 0){
            toast.warn("🐎 Select NFT quantity to mint!")
         }else{
            buyWithZefi(valueState)
         }
      }else{
         aproveContract();
      }
   }
}

 const options = [
   {label: "Select NFT quantity:", value: 0},
   {label: "1 NFT", value: 1},
   {label: "2 NFTs", value: 2},
   {label: "3 NFTs", value: 3},
   {label: "4 NFTs", value: 4},
   {label: "5 NFTs", value: 5},
   {label: "6 NFTs", value: 6},
   {label: "7 NFTs", value: 7},
   {label: "8 NFTs", value: 8},
   {label: "9 NFTs", value: 9},
   {label: "10 NFTs", value: 10},
   {label: "11 NFTs", value: 11},
   {label: "12 NFTs", value: 12},
   {label: "13 NFTs", value: 13},
   {label: "14 NFTs", value: 14},
   {label: "15 NFTs", value: 15},
   {label: "16 NFTs", value: 16},
   {label: "17 NFTs", value: 17},
   {label: "18 NFTs", value: 18},
   {label: "19 NFTs", value: 19},
   {label: "20 NFTs", value: 20},
   // more options...
];


{/* 
// let logoToken = 'https://zcore.network/coins/ZEFI.png';
const [logoToken,setLogoToken] = useState(unknowntoken)
const getData = async () => {

   try {
      const jsonValue = await AsyncStorage.getItem('@tokens_zefi')
      const dataTokens = JSON.parse(jsonValue);

            for (let i = 0; i < dataTokens.length; i++) {      
                // // console.log('logoToken: ' + newToken);
                // // console.log('logoToken: ' + dataTokens[i].address);
               if((newToken.toLowerCase() === dataTokens[i].address.toLowerCase()) && dataTokens[i].logoURI !== undefined){
                  // logoToken = dataTokens[i].logoURI;
                  setLogoToken(dataTokens[i].logoURI);
                  // console.log('logoToken #################################################');
                  // console.log('logoToken: ' + dataTokens[i].name);
                  // console.log('logoToken: ' + dataTokens[i].logoURI);
                  // console.log('logoToken #################################################');   
               }         
            }

   } catch(e) {
      
    }
}

getData();
// console.log('logoToken: '+ logoToken)
*/}

const storeData = async (key, value) => {
   try {
     await AsyncStorage.setItem(key, value)
   } catch (e) {
     // saving error
   }
 }



const getMyTokens = async () => {

   try {
      const jsonValue = await AsyncStorage.getItem('@mytokens')
      const dataTokens = JSON.parse(jsonValue);

            for (let i = 0; i < dataTokens.length; i++) {      
                // // console.log('logoToken: ' + newToken);
                // // console.log('logoToken: ' + dataTokens[i].address);
               if((newToken.toLowerCase() === dataTokens[i].address.toLowerCase()) && dataTokens[i].logoURI !== undefined){
                  // logoToken = dataTokens[i].logoURI;
                  // setLogoToken(dataTokens[i].logoURI);
                  // console.log('logoToken #################################################');
                  // console.log('logoToken: ' + dataTokens[i].name);
                  // console.log('logoToken: ' + dataTokens[i].logoURI);
                  // console.log('logoToken #################################################');   
               }         
            }

   } catch(e) {
      
    }
}

// getData();
// // console.log('logoToken: '+ logoToken)

const CardBalance = props => {
// if(isZefi){

{/*
   return <ContainerTop>
      <ContainerButton>
                  <Button2 style={{ marginRight: "10px" }}>
                     <ImageBox src={logoEth} />
                     <strong><Text>BUY WITH ETH</Text></strong>
                  </Button2>
   
                  <Button2 style={{ marginRight: "10px" }}>
                     <ImageBox src={logoZefi} />
                     <strong><Text>BUY WITH ZEFI</Text></strong>
                  </Button2>            
      </ContainerButton>
      <ContainerButton>
                  <Button2 style={{ marginRight: "10px" }}>
                     <ImageBox src={logoZbo} />
                     <strong><Text>BUY WITH ZBO</Text></strong>
                  </Button2>
   
                  <Button2>
                     <ImageBox src={logoPix} />
                     <strong><Text>BUY WITH PIX</Text></strong>
                  </Button2>
            </ContainerButton>    
      </ContainerTop>
   }else{
   */}
      if(isConnect){

            return <ContainerTopMin>            
            <Button6 style={{ marginRight: "0px", padding: '10px' }}>
                  <Container>
                     <Row>
                        <Col md={2}>
                           <ImageBox width={40} height={40} src={logoToken} />
                        </Col>
                        <Col md={10}>
                           <strong><Text style={{ color: '#00414a' , fontSize: '18px' }} >{tokenBalance} {ticker.toUpperCase()}</Text></strong>
                           <Text style={{ color: '#adadad' , fontSize: '14px' }} >Balance</Text>
                        </Col>
                     </Row>
                  </Container>
            </Button6>


{/*
            <Button6 style={{ marginRight: "0px", padding: '10px' }}>
                      <ImageBox src={logoToken} />
                     <strong><Text style={{ color: '#00414a' , fontSize: '18px' }} >{tokenBalance} {ticker.toUpperCase()}</Text></strong>
                     <Text style={{ color: '#adadad' , fontSize: '14px' }} >Balance</Text>
            </Button6>

         <Button5>
            <ImageBoxMax 
               width={50}
               height={50}
               defaultSource={logoToken}
               src={logoToken} 
            />
            <strong>
               <TextBalance>Balance : {tokenBalance} {ticker}</TextBalance>
            </strong>
         </Button5>
*/}
      </ContainerTopMin>            

         
      }else{

         return <ContainerTopMin>            
               <Button6 style={{ marginRight: "0px", padding: '10px' }}>
                  <Container>
                     <Row>
                        <Col md={2}>
                           <ImageBox width={40} height={40} src={logoToken} />
                        </Col>
                        <Col md={10}>
                           <strong><Text style={{ color: '#00414a' , fontSize: '18px' }} >0 {ticker.toUpperCase()}</Text></strong>
                           <Text style={{ color: '#adadad' , fontSize: '14px' }} >Balance</Text>
                        </Col>
                     </Row>
                  </Container>                     
            </Button6>
{/* 
         <Button5>
         <ImageBoxMax src={unknowntoken} />
            <strong>
            <TextBalance>Balance : 0</TextBalance>
            </strong>
         </Button5>
*/}         
      </ContainerTopMin>
      }
   }   
{/* } */}

async function getReciboPrice() {
   let responsePrice = await fetch('https://api.zcore.farm/prices');
   let responseJsonPrice = await responsePrice.json();
   // console.log(responseJsonPrice.ZCRFMv1);   
}


async function getOwners() {
   try {

     let response = await fetch('https://zcorefinance.app/nft_owners_read');
     let responseJson = await response.json();
     let meurecibo = 0
     let totscore = 0;
     let arrNftList = []; //copy array by value

     for (var i = 0; i < responseJson.length; i++)
      {
         var obj = responseJson[i];
         if(obj.owner_of.toLowerCase() == currentAccount.toLowerCase()){
            meurecibo++;
            totscore = totscore + obj.score;
            let imagem;
            let nome;
            if(obj.game_item == 1){
               nome = 'Casa: ' + JSON.parse(obj.game_info).local;
               if(JSON.parse(obj.game_info).apto != '0'){
                 nome = 'Apto: ' + JSON.parse(obj.game_info).apto;
               }
               imagem = 'https://zcorefinance.app/houses/'+ JSON.parse(obj.game_info).local +'.jpg';
             }
             if(obj.game_item == 2){
               nome = 'Veículo'
               imagem = 'https://zcorefinance.app/game_veiculos_foto?hash='+ JSON.parse(obj.game_info).model;
             }              
             if(obj.game_item == 99){
               nome = 'Mansão'
               imagem = 'https://cdn.discordapp.com/attachments/1025144724983644160/1025871096647528589/nophoto.jpg';
             }

            arrNftList.push(
               {
                  id: Number(obj.id), 
                  score: obj.score,
                  rank: obj.rank,
                  nome: nome,
                  imagem: imagem,
               }
               )
            // console.log(`Owner: ${obj.owner_of}`);
         }         
      }

     setTotalScore(totscore.toFixed(2));
     setNftList(arrNftList);
     // console.log(arrNftList);
     setNftOwners(meurecibo);
     // return responseJson.movies;
    } catch(error) {
     console.error(error);
   }
 }



 const Getownersrender = () => {
   if(nftList.length == 0){
      return <></>
   }else{
      return <Container>
         <Row>
         {
            nftList.map(({ id, score, rank, nome, imagem }) => <Col style={{marginTop: '20px'}} key={id}><div style={{ textAlign: 'center' }}>
         <a target='_blank' rel="noreferrer" href={`https://mynft.zcore.network/${id}`}><Image style={{
          width: 200,
          height: 200,
          borderRadius: '20%',
          overflow: 'hidden',
        }} width={200} height={200} src={`https://files.zcore.network/assets/${id}.webp`} /></a>
      <Image style={{
         marginTop: '10px',
          width: 192,
          height: 108,
          borderRadius: '15%',
          overflow: 'hidden',
        }} width={192} height={108} src={imagem} />        
               </div>
                  <Row>
                     <Col><strong><Text style={{ textAlign: 'center', color: '#00414a' , fontSize: '18px' }} >#{id}<br /><small>{nome}</small></Text></strong></Col>
                  </Row>
                  <Row>
                     <Col><Text style={{ textAlign: 'center', color: '#adadad' , fontSize: '14px' }} >Rank: {rank}</Text></Col>
                     <Col><Text style={{ textAlign: 'center', color: '#adadad' , fontSize: '14px' }} >Score: {score}</Text></Col>                  
                  </Row>
                  <Row>
                  <Col style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }} md={12}><Button2><a target='_blank' style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" href={`https://api.opensea.io/api/v1/asset/0x9c9004de368b1968c953a5022271de80310489e4/${id}/?force_update=true`}><strong>Refresh Metadata</strong></a></Button2></Col>
                  <Col style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }} md={12}><Button2><a target='_blank' style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" href={`https://opensea.io/assets/ethereum/0x9c9004de368b1968c953a5022271de80310489e4/${id}`}><strong>Open in Opensea</strong></a></Button2></Col>
                  </Row>
               </Col>)
         }
         </Row>
         </Container>
   }
 };
 

const selectstyle = {
   menu: ({ width, ...css }) => ({
      ...css,

      width: "max-content",
      minWidth: "100%",
      fontWeight: 'bold',
 }),
 control: base => ({
   ...base,
   border: '2px solid #adadad',
   fontWeight: 'bold',
   // This line disable the blue border
   boxShadow: "none"
 })
 }

 const selectstyleQtd = {    
   menu: ({ width, ...css }) => ({
      ...css,      
      width: "max-content",
      minWidth: "100%",
      fontWeight: 'bold',
 }),
 control: base => ({
   ...base,
   border: '2px solid #adadad',
   fontWeight: 'bold',
   // This line disable the blue border
    boxShadow: "none"
 }),
 }


 const renderer = ({ days, hours, minutes, seconds, completed }) => {
   // Render a countdown
   return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
};

const rendererwhite = ({ days, hours, minutes, seconds, completed }) => {
   // Render a countdown
   return <span style={{ fontSize: '18px', color: '#00414a' }} ><strong>WhiteList Open Sale : {days}d {hours}h {minutes}m {seconds}s</strong></span>;
};

 const BuyButtons = props => { 
   // if(){
   //    return null;
   // }else{
      if (loadButton) {
         return <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>;
      }else{
         if(isConnect){
            if(saleIsActive){

               if(!iswhitelist){
                  return <Card style={{ marginBottom: '30px' }}><strong>❌ Your address not is whitelisted.<br />Wait for date to public sale:</strong><br /><Text>🐎 July 1st</Text>
                  <br /><strong>💳  Or Buy with your Credit Card!</strong>
                  </Card>;
               }else{
                  if(approved){
                     return <Button onClick={buyZefi}><strong>Mint NFT with {ticker}</strong></Button>;
                  }else{
                     return <Button onClick={buyZefi}><strong>Approve Contract</strong></Button>;
                  }                  
               }


            }else{
               return <ButtonNada style={{ fontSize: '16px' }} ><Countdown 
               renderer={renderer}
               date={new Date(mintStart)} 
            /></ButtonNada>;
            }
         }else{
            return <ButtonNada>Connect your Wallet...</ButtonNada>;
         }
      }
   // }
 };


 const [crossmintQtd, setCrossmintQtd] = useState(0);

 const CrossMintTest = props => { 
   if(crossmintQtd > 0){
   return (
       <CrossmintPayButton
           collectionTitle="ZCore Farm Horses"
           collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
           collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
           clientId="053a8b7e-9091-4a3b-83da-68422c093074"
           environment="staging"
           className="customcrossmintbutton"
           mintConfig={{
             "type":"erc-721",
             "totalPrice": String(crossmintQtd * 0.03),
             "_mintAmount": String(crossmintQtd)
           }}
       />
   );
         }else{
            return <></>;
         }
 }
 
 const CrossMint = props => { 
   if(crossmintQtd > 0){
      return (
       <CrossmintPayButton
           collectionTitle="ZCore Farm Horses"
           collectionDescription="ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs* and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club."
           collectionPhoto="https://club.zcore.network/assets/images/nfts/10.png"
           clientId="b6c26485-87b5-4a37-9881-051e721604c9"
           environment="production"
           className="customcrossmintbutton"
           mintConfig={{
             "type":"erc-721",
             "totalPrice": String(crossmintQtd * 0.03),
             "_mintAmount": String(crossmintQtd)
           }}
       />
      );
   }else{
      return <></>;
   }   
 }

 const customStyles = {
   placeholder: (provided) => ({
     ...provided,
     position: 'static',
     transform: 'none',
   }),
   singleValue: (provided) => ({
     ...provided,
     position: 'static',
     transform: 'none',
   }),
 }


 const optionsCredit = [
   {label: "Select quantity to pay with credit card:", value: 0},
   {label: "Mint 1 NFT with credit card", value: 1},
   {label: "Mint 2 NFTs with credit card", value: 2},
   {label: "Mint 3 NFTs with credit card", value: 3},
   {label: "Mint 4 NFTs with credit card", value: 4},
   {label: "Mint 5 NFTs with credit card", value: 5},
   {label: "Mint 6 NFTs with credit card", value: 6},
   {label: "Mint 7 NFTs with credit card", value: 7},
   {label: "Mint 8 NFTs with credit card", value: 8},
   {label: "Mint 9 NFTs with credit card", value: 9},
   {label: "Mint 10 NFTs with credit card", value: 10},
   {label: "Mint 11 NFTs with credit card", value: 11},
   {label: "Mint 12 NFTs with credit card", value: 12},
   {label: "Mint 13 NFTs with credit card", value: 13},
   {label: "Mint 14 NFTs with credit card", value: 14},
   {label: "Mint 15 NFTs with credit card", value: 15},
   {label: "Mint 16 NFTs with credit card", value: 16},
   {label: "Mint 17 NFTs with credit card", value: 17},
   {label: "Mint 18 NFTs with credit card", value: 18},
   {label: "Mint 19 NFTs with credit card", value: 19},
   {label: "Mint 20 NFTs with credit card", value: 20},
   // more options...
];  


// create a function that handle the React-select event and
// save the value of that event on an state every time the component change
const handlerCredit = (event) => {
  const valueCredit = event.value
  console.log(valueCredit);
  setCrossmintQtd(valueCredit);
}


 const WhiteListButtons = props => {       

   if (loadButton) {
      return <LoadingDiv><SpinnerCircularSplit/></LoadingDiv>;
   }else{
      if(isConnect){
               if(iswhitelist){
                  return <Card style={{ marginBottom: '30px' }}><strong>✅ Your address is whitelisted.<br />Wait for the date to mint:</strong><br /><Text style={{ fontSize: '13px' }} >🐎 {currentAccount}</Text></Card>;
               }else{
                  return <Button 
                        onClick={enterWhiteList}><strong>Enter WhiteList</strong></Button>;
               }
      }else{
         return <></>;
      }
   }
};

 const AlertaBsc = props => { 
   if (currentChainId == 56) {
   return <ContainerMainMin>
            <Text style={{ fontSize: '14px' , textAlign: 'center', maxWidth: '300px' }} >      
            ⚠️ NFTs that are bought with tokens from the BSC network, but you will receive the NFTs minted on the Ethereum blockchain. ➡️ Click on BSC icon to change network.
            </Text>         
         </ContainerMainMin>
   }else{
      return <ContainerMainMin>
      <Text style={{ textAlign: 'center', maxWidth: '300px' }} >      
      ⚠️ NFTs are minted on the ETH network. ➡️ Click on ETH icon to change network.
      </Text>         
   </ContainerMainMin>
   }
 }



 const ComponentTab = props => { 
   return <Tabs>
     <TabList>
     <Tab><b>
         <BrowserView>BSC Tokens</BrowserView>
         <MobileView>BSC</MobileView>
         </b></Tab>      
       <Tab><b>
         <BrowserView>Buy with Credit Card</BrowserView>
         <MobileView>Credit Card</MobileView>
         </b></Tab>
       {/* <Tab><b>PIX</b></Tab>       */}
       <Tab><b>ETH</b></Tab>       
       </TabList>



       <TabPanel>
     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            🔽 You can use another tokens from the BSC network to mint your NFT:
            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <Select
         isSearchable={false}
         styles={selectstyle}
         name="mytokens"
         placeholder="Select another token to Mint:"
         onChange={setmytoken}
         options={dbMy}
         getOptionLabel={(dbMy) => dbMy.tokenName}
         getOptionValue={(dbMy) => dbMy.contractAddress} // It should be unique value in the options. E.g. ID
      />              
      </div>      

      <ColoredLine color="#00414a" />

      🤑 Earn <strong>0.001 ETH</strong> (<a target="blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000000' }} href="https://bit.ly/3on4geD"><FaExternalLinkAlt/> from BNB Chain</a>) referring friends to mint NFTs with your referral link<br/>
      (<a target="blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000000' }} href={`https://bscscan.com/token/0x2170ed0880ac9a755fd29b2688956bd959f933f8?a=${currentAccount}`}><FaExternalLinkAlt/> total: {totalReferrals} NFTs sold</a>)<br /><br /><strong>Your referral link for mint with {tokenTicker} token:</strong>
      {customLink !== 'https://join.zcore.network/' &&
      <>
         <CardRed style={{ marginTop: '10px' }}>
            <ClipboardCopy copyText={customLink.replace('bit.ly/','zcoreclub.io/')} />
         </CardRed>
         <Text style={{ marginTop: '15px', fontSize: '12px' }}>
         (referral valid only for mint on the BSC network )        
         </Text>
         </>
      }

   </DivCard4>     
      </ContainerButton4>       
      </div>      
     </TabPanel>     


     <TabPanel>


     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            💳 You can use your credit card to purchase NFTs. Select the NFTs quantity and click the button : 

            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Select
              styles={customStyles}
              isSearchable={false}
              onChange={handlerCredit}
              defaultValue={optionsCredit[crossmintQtd]}
              options={optionsCredit}
   />
</div>
              
<div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
               <CrossMint/>
</div>
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>

{/*

     <TabPanel>

     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>

            💰 To purchase NFTs using Pix (Brazil) download the ZCore Finance app : 

            <div style={{ marginTop: '15px', marginBottom: '15px',  display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <a target='_blank' rel="noreferrer" href="https://apps.apple.com/br/app/zcore-finance/id1570794397"><img style={{ width: '163px', height: '50px', margin: '5px' }} src={appstore} /></a>
            <a target='_blank' rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.zcorefinance"><img style={{ width: '163px', height: '50px', margin: '5px' }} src={googleplay} /></a>
</div>
              
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>
 */}

     <TabPanel>


     <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <ContainerButton4>
            <DivCard4>
            🔥 Connect your wallet to Ethereum Mainnet, select the NFTs quantity and click the MINT button.<br />
            Minting through the ETH network is automatic.
            </DivCard4>     
      </ContainerButton4>    
</div>

     </TabPanel>


   </Tabs>
 };




 const dbMyTokens = async () => {   
   try {
      const jsonValue = await AsyncStorage.getItem('@mytokens')
      const dataTokens = JSON.parse(jsonValue);   
      setDbMy(dataTokens)
   } catch (e){

   }
   // const arr = []
   // Object.keys(MyObject).forEach(key => arr.push({name: key, value: MyObject[key]}))
 }

 
 useEffect(() => {   
   // getReciboPrice();
    getTicker();    
    dbMyTokens();
    // funcGetPriceUsd();    
    getZefiPrice();
    getLogo();
    if(nftOwners == -1){
      getOwners();
    }
  }, [currentWeb3, tokenUSD]);
  
  
let nameBuy = 'Buy ZEFI';
let linkBuy = 'https://pancakeswap.finance/swap?outputCurrency=0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07';

if(!isZefi){
   nameBuy = `Buy ${ticker}`;
   linkBuy = `https://pancakeswap.finance/swap?outputCurrency=${newToken}`;
}


 const IconToken = () => {
   <ImageBox width={40} height={40} src={logoToken} />
    /*
   const [showIconToken, setShowIconToken] = React.useState(false);
   useEffect(() => {
     // setTimeout(function () {
      setShowIconToken(true);
     // }, 500);
   }, []); 

   return (
     <div>
       <div>
         {showIconToken ? (
            <ImageBox width={40} height={40} src={logoToken} />
         ) : (
            <div style={{ width: '40px', height: '40px' }}></div>
         )}{" "}
       </div>
     </div>
   );
   */
 };




 function ClipboardCopy({ copyText }) {
   const [isCopied, setIsCopied] = useState(false);
 
   // This is the function we wrote earlier
   async function copyTextToClipboard(text) {
     if ('clipboard' in navigator) {
       return await navigator.clipboard.writeText(text);
     } else {
       return document.execCommand('copy', true, text);
     }
   }
 
   // onClick handler function for the copy button
   const handleCopyClick = () => {
     // Asynchronously call copyTextToClipboard
     copyTextToClipboard(copyText)
       .then(() => {
         // If successful, update the isCopied state value
         setIsCopied(true);
         setTimeout(() => {
           setIsCopied(false);
         }, 1500);
       })
       .catch((err) => {
         console.log(err);
       });
   }
 
   return (
     <div>
       {/* <input type="text" value={copyText} readOnly /> */}
       <Text style={{ fontWeight: 'bold' }}>
               {copyText}
            </Text>
       {/* Bind our handler function to the onClick button property */}
       
       <Button style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '15px', marginBottom: '5px', padding: '3px' }} onClick={handleCopyClick}>
         <span><FaCopy style={{ marginRight: '5px' }}/>{isCopied ? 'Copied!' : 'Copy'}</span>
       </Button>
       
     </div>
   );
 }



 const [statMenu, setStatMenu] = useState(1);

const clickDivMint = (event) => {
   setStatMenu(1);
}


const clickDivStaking = (event) => {
   setStatMenu(2);
}


const clickDivDao = (event) => {
   window.open("https://dao.zcore.network/", "_blank");
}


const clickOpenVaults = (event) => {
   window.open("https://dao.zcore.network/", "_blank");
}

 const ImagemCavalo = () => {
   return (
      <div>
        <div>
             <Image width={300} height={300} src={preview}/>
        </div>
      </div>
    );
    /*
   const [showElement, setShowElement] = React.useState(false);
   useEffect(() => {
      if(isMobile){
         // setTimeout(function () {
            setShowElement(true);
         // }, 1000);
      }
   }, []); 

   if(isMobile){
      return (
         <div>
           <div>
             {showElement ? (
                <Image width={300} height={300} src={preview}/>
             ) : (
                <div style={{ width: '300px', height: '307px' }}></div>
             )}{" "}
           </div>
         </div>
       );
   }else{
      return (
         <div>
           <div>
                <Image width={300} height={300} src={preview}/>
           </div>
         </div>
       );
   }
   */
 };

 const CardCavalo = props => { 
   if(statMenu == 1){
   return  <Card>

   <BoxTitle>
      <h2><a style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={opensea}>About Farm Horses <FaExternalLinkAlt/></a></h2>
    </BoxTitle>


      <ImageContainer>         
         <ImagemCavalo/>
      </ImageContainer>            


 <TextPrice style={{ color: '#adadad' }}>
{/*
<strong>
Mint Price
</strong>
 */}
</TextPrice>
<TextPrice>
   <strong>
   1 NFT = 
      {(ticker == undefined) &&      
        ` 0`
      }{
        ` ${(new BigNumber(zefiPrice + "e-" + decimals)).toFixed(2)} ${ticker}`
      }


<TextGray>(${priceUnique.toFixed(2)})</TextGray>
</strong>
</TextPrice>           

         

   </Card>

   }else{
      return   <Card>

      <BoxTitle>
                <h2><a style={{ color: '#000000' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={opensea}>Farm Horses <FaExternalLinkAlt/></a></h2>
       </BoxTitle>
         <ImageContainer>         
            <ImagemCavalo/>
         </ImageContainer> 

         <Button style={{ marginTop: '25px', marginBottom: '10px', padding: '5px' }} ><a style={{ textDecoration: 'none', color: '#FFFFFF' }} target="_blank" href="https://vaults.zcore.farm/stake"><strong style={{ fontSize: '16px' }}><FaExternalLinkAlt/> STAKE</strong></a></Button>

      </Card>      
   }
 }




   return (
      <>






         <ContainerTop/>


<ContainerMainMinus>
<ContainerButton5>
      <DivCard5 onClick={clickDivMint}>
      <FaPlusCircle style={{ marginRight: '5px' }}/> Mint
      </DivCard5>     
      <DivCard5 onClick={clickDivStaking}>
      <FaHandHoldingUsd style={{ marginRight: '5px' }}/> Staking
      </DivCard5>     
      <DivCard5 onClick={clickDivDao}>
      <FaUniversity style={{ marginRight: '5px' }}/> DAO
      </DivCard5>           
</ContainerButton5>
</ContainerMainMinus>

      <ContainerMain>


<CardCavalo/>

           <label style={{ marginTop: '0px' }}>           





    {/* <ColoredLine color="#00414a" /> */}

{/*     {isConnect && */}

    



{statMenu == 2 &&
<ColLat>   
<BoxTitle>
{isConnect  ?
<>
<ContainerTopMin>            
            <DivCard style={{ marginRight: "0px", padding: '10px' }}>
                  <Container>
                     <Row>
                        <Col md={2}>
                           <ImageBox width={40} height={40} src={zcrfm} />
                        </Col>
                        <Col md={10}>
                           <strong><Text style={{ color: '#00414a' , fontSize: '18px' }} >{reciboBalance} <small>{nomeRecibo}</small></Text></strong>
                           <Text style={{ color: '#adadad' , fontSize: '14px' }} >Balance</Text>
                        </Col>
                     </Row>
                     {/*
                     <Row>
                        <Button style={{ marginTop: '15px', marginBottom: '10px', padding: '5px' }} ><a style={{ textDecoration: 'none', color: '#FFFFFF' }} target="_blank" href="https://vaults.zcore.farm/stake"><strong style={{ fontSize: '16px' }}><FaExternalLinkAlt/> STAKE</strong></a></Button>
                     </Row>
                  */}
                  </Container>
            </DivCard>


      </ContainerTopMin>      



{nftOwners >= 0 ?
   <ContainerButton3>          
      <DivCard style={{ marginTop: '10px' , padding: '10px'}} >
      <Text style={{ fontSize: '16px' }}>
Your wallet:<br /><strong style={{ fontSize: '18px' }}>{nftOwners} NFTs</strong>
                        </Text>    
                        <Text style={{ marginTop: '5px' , color: '#00414a' , fontSize: '14px' }} >🎮 Total Score : {totalScore}</Text>
                        <Text style={{ marginTop: '10px' , fontSize: '16px' }}>
                        You will receive:<br /><strong style={{ fontSize: '18px' }}>{totalScore} {nomeRecibo}</strong>
      </Text>                 
      </DivCard>

<DivCard style={{ maxWidth: '250px' , marginTop: '20px' , padding: '10px'}} >
<Text style={{ fontSize: '16px' }}>
🎮 Each NFT has a score according to your rarity.< br />   
⚠️ Please wait 24 hours for the {nomeRecibo} tokens to be automatically received in your wallet.
{/* {nomeRecibo} Received:<br /><strong style={{ fontSize: '18px' }}>0 NFTs</strong> */}
</Text>    

</DivCard>
</ContainerButton3>

:
<DivCard style={{ marginTop: '10px' , padding: '10px'}} >
   <Text style={{ fontSize: '16px' }}>Loading your NFTs...</Text>                 
</DivCard>  

}

   </>
:
<ContainerButton3>               
<DivCard style={{ marginTop: '10px' , padding: '10px'}} >
   <Text style={{ maxWidth: '350px' }}>
         Connect your wallet...
   </Text>
</DivCard>
{/*
<DivCard style={{ marginTop: '20px' , padding: '10px'}} >
                        <Text style={{ fontSize: '16px' }}>
                          Next Snapshot:<br /><strong style={{ fontSize: '18px' }}><Countdown 
               renderer={renderer}
               date={new Date(nextSnapshot)} 
            /></strong>
                        </Text>                 
                     </DivCard>  
*/}
   </ContainerButton3>
}

{/*
<ContainerButton3>               
<DivCard style={{ marginTop: '20px' , padding: '10px'}} >
                        <Text style={{ fontSize: '16px' }}>
                          Next Snapshot:<br /><strong style={{ fontSize: '18px' }}><Countdown 
               renderer={renderer}
               date={new Date(nextSnapshot)} 
            /></strong>
                        </Text>                 
                     </DivCard>  

   </ContainerButton3>
*/}   

</BoxTitle> 
</ColLat>
} 




    
{statMenu == 1 &&
   <ColLat>   

   <Select
      styles={selectstyleQtd}
      isSearchable={false}
      onChange={handler}
      defaultValue={options[0]}
      options={options}
    />


    <BoxTitle>
    {isConnect  ?
      <Text style={{ maxWidth: '350px' }}>   
         <strong>
            Total : {swapTotal} {ticker}
         </strong>
      </Text>
      :
      <Text style={{ maxWidth: '350px' }}>   
         <strong>
            Total : 0 {ticker}
        </strong>
      </Text>
   }
      <TextGray style={{ fontWeight: 'bold' }}>(${(priceUnique * valueState).toFixed(2)})</TextGray>
    </BoxTitle>   

    <BuyButtons/>
    
      <CardBalance/>   
{/*
    <AlertaBsc/>      
*/}

    <ContainerButton3>
    {((Number(nftTotalEth) + Number(nftTotalEthOLD)) !== (Number(nftTotal) + Number(nftTotalOLD)))  
    ?
            <DivCard>
               NFT Received : <strong>{Number(nftTotalEth) + Number(nftTotalEthOLD)} / {Number(nftTotal) + Number(nftTotalOLD)}</strong><br />
                  <Text style={{ marginTop: '5px' , fontSize: '14px' }}>(please wait bridge)</Text>
            </DivCard>
     :
      <DivCard>
         NFT Received : <strong>{Number(nftTotalEth) + Number(nftTotalEthOLD)} / {Number(nftTotal) + Number(nftTotalOLD)}</strong>
         {((Number(nftTotalEth) + Number(nftTotalEthOLD)) > 0)  &&
            <Text style={{ marginTop: '5px' , fontSize: '14px' }}>(see in Ethereum network)</Text>
         }
      </DivCard>
   }
      </ContainerButton3>    

      
         <BoxTitle>
            <a style={{ fontWeight: 'bold', fontSize: '20px', color: '#00414a' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={linkBuy}>{nameBuy} <FaExternalLinkAlt/></a>
         </BoxTitle>

</ColLat>     
}

{!mintEnabled &&

   <ColLat>
   
      <WhiteListButtons />
      <DivWhiteList>      
<Text>
Whitelist Sale Price: <strong>{(ticker == undefined) ?
        `0`
      :
        `${(new BigNumber((zefiPrice) + "e-" + decimals)).toFixed(6)} ${ticker}`
      }</strong><br />
Public Sale Price: <strong>{(ticker == undefined) ?
        `0`
      :
        `${(new BigNumber((zefiPrice + "e-" + decimals)/2)*3).toFixed(6)} ${ticker}`
      }</strong>
</Text>
</DivWhiteList>        
<ColoredLine color="#00414a"/>
<Text>
Click on BSC icon to change Network.
</Text>
<ColoredLine color="#00414a"/>
<Countdown 
      renderer={rendererwhite}
      date={new Date(whiteStop)} 
         />
 <ColoredLine color="#00414a"/>
         <BoxTitle>
            <a style={{ fontWeight: 'bold', fontSize: '20px', color: '#00414a' , textDecoration: 'none' }} rel="noreferrer" target='_blank' href={linkBuy}>{nameBuy} <FaExternalLinkAlt/></a>
         </BoxTitle>

   </ColLat>
}

<ToastContainer 
   position="top-right"
   autoClose={10000}
   hideProgressBar={false}
   newestOnTop={false}
   closeOnClick
   rtl={false}
   pauseOnFocusLoss
   draggable
   pauseOnHover
/>


</label>



      </ContainerMain>      


      <ContainerMainSelect>

      <ComponentTab/>


</ContainerMainSelect>

<ContainerMainSelect>
   <Getownersrender/>
</ContainerMainSelect>

{/*
{customLink !== 'https://join.zcore.network/' &&

<div style={{ display: 'flex', margin: '0px', justifyContent:'center', alignItems:'center'}}>
 <CardRed>            
      Your Referral link<br />
      <ClipboardCopy copyText={customLink.replace('bit.ly/','zcoreclub.io/')} />
 </CardRed>
</div>  
}
*/}
      </>
   );
}

