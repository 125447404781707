import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --main-color: #00414a;
        --heading-color: #00414a;
        --heading-color2 : #00414a;
        --paragraph-color: #4d4d4d;

        --light-gray:#adadad;

        --white: #f4f4f4;

        --heading-font: "Poppins", sans-serif;
        --body-font: "Roboto", sans-serif;

        --body-font-size: 1rem;
        --line-height30: 1.7;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%; //15px
        }
        @media (max-width: 720px) {
            font-size: 87.5%; //14px
        }
    }

    //REM = 1rem = 16px
 
    body {
        /* background: transparent linear-gradient(270deg,#0c5543 50%,#32a958) repeat scroll 0 0; */
        background: #f0f0f0;
        -webkit-font-smoothing: antialiased;
        font-family: var(--body-font);
    }

    body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

`;